import React from 'react';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { customStyles } from './config';

const renderActions = ({ row, url, statusSearch }) => {
    return (
        statusSearch === 'rejected' ?
        <Link className="button-detail" to={`${url}/rejected/${row?.id}`}>Ver detalle</Link> :
        <Link className="button-detail" to={`${url}/${row?.id}`}>Ver detalle</Link>
    );
};

export default function DataCRUD({ url, data, columns, sortFunction, statusSearch }) {

    const actions = {
        name: '',
        cell: (row) => renderActions({ row, url, statusSearch }),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
    };

    const columnsWithActions = [...columns, actions];

    return (
        <DataTable
            customStyles={customStyles}
            columns={columnsWithActions}
            data={data}
            highlightOnHover
            persistTableHead
            noDataComponent={''}
            onSort={sortFunction}
            sortServer
            striped
            
        />
    );
}

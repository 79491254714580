import React from 'react';

export default function StatusDetailClient(props) {
    return (
        <>
            {
                props.status_id.key === 'approved' ? (
                    props.status_internal.key === 'inside' ? (
                        <span className='status-request-approved'>
                            En puerto
                        </span>
                    ) : props.status_internal.key === 'input_weighing' || props.status_internal.key === 'out_weighing' ? (
                        <span className='status-request-approved'>
                            Procesado
                        </span>
                    ) : props.status_internal.key === 'created' ? (
                        <span className='status-request-approved'>
                            Aprobado
                        </span>
                    ) : (
                        <span className='status-request-approved'>
                            Radicado
                        </span>
                    )
                ) : (
                    <span className='status-request-reject'>
                        Rechazado
                    </span>
                )
            }
        </>

    );
}
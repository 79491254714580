import { combineReducers } from 'redux';

// reducers
import authReducer from './Auth/auth.reducer';
import loaderReducer from './Loader/loader.reducer';
import toastReducer from './Toast/toast.reducer';
import operationTypesReducer from './OperationTypes/operation_types.reducer';
import incomeTypesReducer from './IncomeTypes/income_types.reducer';
import requestReducer from './Requests/requests.reducer';
import usersReducer from './Users/users.reducer';

const rootReducer = combineReducers({
    auth: authReducer,
    loader: loaderReducer,
    toast: toastReducer,
    operation_types: operationTypesReducer,
    income_types: incomeTypesReducer,
    requests: requestReducer,
    users: usersReducer
});

export default rootReducer;

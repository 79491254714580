
import ExportReact from 'react-export-excel-xlsx-fix';

const ExcelFile = ExportReact.ExcelFile;
const ExcelSheet = ExportReact.ExcelFile.ExcelSheet;
const ExcelColumn = ExportReact.ExcelFile.ExcelColumn;

export default function ExportReport({ listSelectExport }) {
    return (
        <ExcelFile element={<button id="file-export"></button>} filename="Lista de solicitudes">
            <ExcelSheet data={listSelectExport} name="Hoja 1">
                <ExcelColumn label="terminalPortuariaNit" value="code_port" />
                <ExcelColumn label="sistemaEnturnamientoId" value="income_type" />
                <ExcelColumn label="tipoOperacionId" value="operation_type" />
                <ExcelColumn label="fechaOfertaSolicitud" value="created_at" />
                <ExcelColumn label="empresaTransportadoraNit" value="nit_transport_company" />
                <ExcelColumn label="vehiculoNumPlaca" value="vehicle_plate" />
                <ExcelColumn label="CONDUCTOR" value="conductor_name" />
                <ExcelColumn label="conductorCedulaCiudadania" value="conductor_identification" />
                <ExcelColumn label="numManifiestoCarga" value="manifest_number" />
                <ExcelColumn label="FECHA ENTRADA" value="in_date" />
                <ExcelColumn label="FECHA SALIDA" value="out_date" />
                <ExcelColumn label="entradaTerminal" value="in_hour" />
                <ExcelColumn label="pesajeEntrada" value="in_weigt" />
                <ExcelColumn label="salidaTerminal" value="out_hour" />
                <ExcelColumn label="pesajeSalida" value="out_weigt" />
                <ExcelColumn label="REPORTE" value="settled_id" />
            </ExcelSheet>
        </ExcelFile>
    );
}
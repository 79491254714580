export const customStyles = {
    headCells: {
        style: {
            padding: '0.5rem',
            whiteSpace: 'nowrap',
            fontWeight: 600,
            textTransform: 'uppercase',
            color: 'rgba(156, 163, 175, 1)',
            backgroundColor: 'transparent',
        },
    },
    cells: {
        style: {
            padding: '0.5rem',
            whiteSpace: 'wrap',
            fontSize: '0.875rem',
            fontWeight: 500,
            lineHeight: '1.25rem',
        },
    },
    rows: {
        style: {
            borderWidth: '0.5px',
            borderColor: 'transparent',
        },
    },
};

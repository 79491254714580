import React from 'react';
import { Grid } from '@material-ui/core';

export default function WeigthTotal(props) {
    return (
        <>
            <span className="title-weigth">Pesado neto</span>
            <div className="content-info-request">
                <Grid container spacing={1} className="row-info">
                    <Grid item xs={12}>
                        <div className="title-detail">
                            Pesaje neto
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="weigth-value">
                            {props.weigt} KG
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}
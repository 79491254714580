import ActionTypes from '../actionTypes';

const initialState = {
    show: false,
    message: null,
    type: 'success',
    duration: 7000,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.START_TOAST:
            return {
                ...state,
                show: true,
                message: action.payload.message,
                type: action.payload.type,
                duration: action.payload?.duration || 7000,
            };
        case ActionTypes.STOP_TOAST:
            return { ...state, ...initialState };
        default:
            return state;
    }
};

export default reducer;

import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import ModalGeneric from '../modal/modalGeneric';

import { Grid } from '@material-ui/core';

// images
import iconFormSuccess from '../../images/icon-form-success.svg';
import iconNoApproved from '../../images/no-approved.svg';
import { Alignment } from 'react-data-table-component';
import { deleteRequest } from '../../redux/Requests/requests.actions';



export default function StatusApproved(props) {
    const [showModal, setShowModal] = React.useState(false);
    const dispatch = useDispatch();
    const history = useHistory();

    const handleAceptModal = (type) => {
        setShowModal(false);
        onSubmit(props)
    }

    const onSubmit = async (props) => {
        dispatch(deleteRequest(props.id, history));
    };

    return (
        <div className="box-welcome-status-approve">
            {props.status === "approved" ? (
                <>
                    <img src={iconFormSuccess} alt="" />
                    <div className="approved-title">
                        <p>Su vehículo con placa <span>{props.vehicle_plate}</span>  ha sido ingresado a nuestro sistema satisfactoriamente</p>
                    </div>
                    <span className="approved-number">No. de registro: {props.request_number}</span>
                    <span className="approved-date">Fecha de solicitud: {moment(props.request_date).format("DD/MM/YYYY")}</span>
                    <br />
                    <div>
                        <div className="title-status-client">
                            Estado
                        </div>
                        <div className="status-detail" style={{ marginTop: '5px' }}>
                            {props.status_internal[0]?.key === 'created' ? 'Por entrar'
                                : props.status_internal[0]?.key === 'inside' ? 'Ingresado'
                                    : props.status_internal[0]?.key === 'input_weighing' ? 'Procesado'
                                        : props.status_internal[0]?.key === 'out_weighing' ? 'Procesado'
                                            : 'Radicado'}
                        </div>
                    </div>
                    <br />
                    <div className="approved-content">
                        <p>
                            <span>Si pasados 5 días el vehículo no ha llegado al puerto, </span>
                            el vehículo saldrá del sistema automáticamente y en
                            caso de ser necesario, deberá registrarse nuevamente
                        </p>
                    </div>
                    <br></br>
                    {props.status_internal[0].key === 'created' && (
                        <>
                            {/* <Link className="button-sencondary button-edit" to={`/user/requests/new?idEdit=${props.id}`}>Editarr</Link> */}
                            <div style={{ width: '80%' }}>
                                <Grid container spacing={4} className="row-info modal-content">
                                    <Grid item xs={5}>
                                        {/* <Link className="button-primary button-send" to="/user/form">Eliminar</Link> */}
                                        <p className="button-sencondary button-delete" onClick={() => { setShowModal(true) }}>Eliminar</p>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Link className="button-sencondary button-edit" to={`/user/requests/new?idEdit=${props.id}`}>Editar</Link>
                                    </Grid>
                                </Grid>
                            </div>
                        </>
                    )}

                </>
            ) : (
                <>
                    <img src={iconNoApproved} alt="" />
                    <div className="approved-title">
                        <p>Su vehículo no puede ser ingresado por</p>
                        <p>{props.response_min}</p>
                    </div>
                    <span className="approved-date">{moment(props.request_date).format("DD/MM/YYYY")}</span>
                    <div className="approved-content">
                        <p>Revisa los datos o comunicate con tu contacto en el Puerto Buenavista </p>
                    </div>
                    <br></br>
                    {props?.status_internal?.[0]?.key === 'created' && (
                        <div style={{ width: '80%' }}>
                            <Grid container spacing={1} className="row-info">
                                <Grid item xs={7}>
                                    <Link className="button-primary button-send" to="/user/form">Enviar información y solicitar ingreso</Link>
                                </Grid>
                                <Grid item xs={5}>
                                    <Link className="button-sencondary button-edit" to={`/user/requests/new?idEdit=${props.id}`}>Editar</Link>
                                </Grid>
                            </Grid>
                        </div>
                    )}
                </>
            )}
            <ModalGeneric
                title={"¿Seguro quieres eliminar esta solicitud?"}
                message={"Recuerda que esta acción es permanente"}
                open={showModal}
                onClose={() => setShowModal(false)}
                onSave={handleAceptModal}
            />
        </div>
    )
}
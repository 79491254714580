import React, { useState, useEffect } from 'react'
import Header from '../../components/Header/Header';
import { Grid, Container } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// import
import InfoRequest from '../../components/InfoRequest';
import ProgressStatus from '../../components/ProgressStatus';
import { auth, requests } from '../../redux/selectors';
import { deleteRequest, getRequest, updateStatusRequest } from '../../redux/Requests/requests.actions';

//images
import iconReturn from '../../images/return.svg';
import WeigthLoad from '../../components/WeigthLoad';
import ModalChangeStatus from './ModalChangeStatus';
import WeigthTotal from '../../components/WeigthTotal';
import moment from 'moment';
import { Link } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import ModalOptionGeneric from '../../components/modal/modalOptionGeneric';

const CustomTab = withStyles({
    root: {
        textTransform: "none",
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '20px',
        lineHeight: '24px',
        color: '#B3B3B3',
        opacity: 1,

        "&.Mui-selected": {
            color: '#036BA2',
        }
    },
})(Tab);


const DetailRequest = (props) => {
    const authState = useSelector(auth);
    const [tabValue, setTabValue] = useState(0);
    const [barValue, setBarValue] = useState(8);
    const [showModal, setShowModal] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);

    const requestState = useSelector(requests);

    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getRequest(props.match.params.id, history));
    }, []);

    useEffect(() => {
        if (requestState.data.request) {
            if (requestState.data.request?.status_id[0]?.key === 'out_weighing' || requestState.data.request?.status_id[0]?.key === 'settled') {
                setTabValue(1);
                setBarValue(100);
            } else {
                setTabValue(0);
                setBarValue(8);
            }
        }
    }, [requestState.data]);


    const handleChangeTab = (event, value) => {
        setTabValue(value);
    }

    const handleAceptModal = (type) => {
        if (requestState.data.request?.status_id[0]?.key === 'created') {
            dispatch(updateStatusRequest(props.match.params.id, 'inside', history));
        } else if (requestState.data.request?.status_id[0]?.key === 'out_weighing') {
            dispatch(updateStatusRequest(props.match.params.id, 'settled', history));
        }
        setShowModal(false);
    }

    const goToBack = () => {
        history.goBack();
    }

    const handleAceptModalDelete = (type) => {
        setShowModalDelete(false);
        onSubmit()
    }

    const onSubmit = () => {
        dispatch(deleteRequest(props.match.params.id, history));
    };


    let rol = '';
    try {
        rol = jwt_decode(authState?.user?.token).role;
    } catch (error) {
        rol = '';
    }

    return (
        <>
            <Header />
            <div className="container" >
                <Container maxWidth="md">
                    <div className="welcome-title">
                        <img className="icon-return" src={iconReturn} alt="" onClick={() => goToBack()} />
                        <span>Detalle vehículo placa {requestState?.data?.request?.vehicle_plate}</span>
                    </div>
                </Container>
                <Container maxWidth="md">
                    <div className="box-welcome-approve" style={{ marginBottom: '100px' }}>
                        <Grid container spacing={1}>
                            <Grid item xs={8}>
                                <div className="title-detail">
                                    Placa vehículo
                                </div>
                                <div className="plate-detail">
                                    {requestState.data.request?.vehicle_plate}
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div className="title-detail">
                                    Estado
                                </div>
                                <div className="status-detail">
                                    {requestState.data.request?.status_id[0]?.key === 'created' ? 'Por entrar' : requestState.data.request?.status_id[0]?.key === 'inside' ? 'En puerto' : requestState.data.request?.status_id[0]?.key === 'input_weighing' ? 'Pesado Entrada' : requestState.data.request?.status_id[0]?.key === 'out_weighing' ? 'Pesado Salida' : 'Radicado'}
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={8}>
                                <div className="title-detail">
                                    Cliente
                                </div>
                                <div className="conductor-detail">
                                    {requestState.data.request?.user?.client}
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div className="title-detail">
                                    Fecha de Estatus
                                </div>
                                <div className="date-detail">
                                    {moment(requestState?.data?.request?.updated_at).format('DD/MM/YYYY')}
                                </div>
                            </Grid>
                        </Grid>
                        <br />
                        <Grid container spacing={1}>
                            <Grid item xs={8}>
                                <div className="title-detail">
                                    Conductor
                                </div>
                                <div className="conductor-detail">
                                    {requestState.data.request?.conductor_name}
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div className="title-detail">
                                    Hora de Estatus
                                </div>
                                <div className="date-detail">
                                    {moment(requestState?.data?.request?.updated_at).format("h:mm a")}
                                </div>
                            </Grid>

                        </Grid>
                        <br />

                        <Grid container spacing={1}>
                            <Grid item xs={8}>
                                <div className="title-detail">
                                    N. Solicitud
                                </div>
                                <div className="conductor-detail">
                                    {requestState.data.request?.request_number}
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                {
                                    requestState.data.request?.multiple === '1'
                                        ? (
                                            requestState.data.request?.date_multiple === moment().format('YYYY-MM-DD') && (
                                                requestState.data.request?.status_id[0]?.key === 'created' ? (
                                                    <>
                                                        <div className="button-primary" style={{ marginTop: '15px' }} onClick={() => setShowModal(true)}>Entrar a puerto</div>
                                                    </>
                                                ) : requestState.data.request?.status_id[0]?.key === 'out_weighing' ? (
                                                    <>
                                                        <div className="button-primary" style={{ marginTop: '15px' }} onClick={() => setShowModal(true)}>Finalizar proceso</div>
                                                    </>
                                                ) : (
                                                    <>
                                                        {/* <div className="button-primary" style={{ marginTop: '15px' }} onClick={() => setShowModal(true)}>Pasar a pesaje</div> */}
                                                    </>
                                                )
                                            )
                                        )
                                        : (
                                            requestState.data.request?.status_id?.[0]?.key === 'created' ? (
                                                <>
                                                    <div className="button-primary" style={{ marginTop: '15px' }} onClick={() => setShowModal(true)}>Entrar a puerto</div>
                                                </>
                                            ) : requestState.data.request?.status_id[0]?.key === 'out_weighing' ? (
                                                <>
                                                    <div className="button-primary" style={{ marginTop: '15px' }} onClick={() => setShowModal(true)}>Finalizar proceso</div>
                                                </>
                                            ) : (
                                                <>
                                                    {/* <div className="button-primary" style={{ marginTop: '15px' }} onClick={() => setShowModal(true)}>Pasar a pesaje</div> */}
                                                </>
                                            )
                                        )}
                            </Grid>
                        </Grid>
                        {
                            rol === 'admin' && (
                                requestState.data.request?.status_id?.[0]?.key === 'created'
                                    || requestState.data.request?.status_id?.[0]?.key === 'inside'
                                    ? (
                                        <>
                                            <br></br>
                                            <br></br>
                                            <div style={{ width: '80%' }}>
                                                <Grid container spacing={4} className="row-info modal-content">
                                                    <Grid item xs={5}>
                                                        <p className="button-sencondary button-delete" onClick={() => { setShowModalDelete(true) }}>Eliminar</p>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <Link className="button-sencondary button-edit" to={`/admin/requests/new?idEdit=${props.match.params.id}`}>Editar</Link>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </>
                                    )
                                    : <></>
                            )
                        }

                        {requestState.data.request && (
                            <ProgressStatus barValue={barValue} />
                        )}

                        {requestState.data?.weigthing ? (
                            <>
                                <Tabs
                                    style={{ paddingTop: '50px', width: '100%' }}
                                    variant="fullWidth"
                                    value={tabValue}
                                    onChange={handleChangeTab}
                                    TabIndicatorProps={{
                                        style: { height: "0px", }
                                    }}>
                                    <CustomTab label="Datos registro" />
                                    <CustomTab label="Datos pesaje" disabled={
                                        requestState.data?.weigthing?.length > 0
                                            ? false
                                            : requestState.data.request?.status_id[0]?.key === 'input_weighing' ||
                                                requestState.data.request?.status_id[0]?.key === 'out_weighing' ||
                                                requestState.data.request?.status_id[0]?.key === 'settled'
                                                ? false
                                                : true
                                    }
                                    />
                                </Tabs>

                                {tabValue === 0 ? (
                                    <>
                                        <InfoRequest
                                            operationType={requestState.data.request?.operation_type}
                                            incomeType={requestState.data.request?.income_type}
                                            nitTransportCompany={requestState.data.request?.nit_transport_company}
                                            nameTransportCompany={requestState.data.request?.name_transport_company}
                                            vehiclePlate={requestState.data.request?.vehicle_plate}
                                            manifestNumber={requestState.data.request?.manifest_number}
                                            multiple={requestState.data?.request?.multiple}
                                            dateMultiple={requestState.data?.request?.date_multiple}
                                            authorizationNumber={requestState.data.request?.authorization_number}
                                            conductorName={requestState.data.request?.conductor_name}
                                            conductorIdentification={requestState.data.request?.conductor_identification}
                                            load_observations={requestState.data.request?.load_observations}
                                            observations={requestState.data.request?.observations}
                                            request_files={requestState.data.request?.request_files}
                                        />
                                    </>
                                ) : (
                                    <>
                                        {requestState.data?.weigthing.length > 0 && (
                                            <>
                                                <WeigthLoad type="in" data={requestState.data?.weigthing[0]} />
                                                <WeigthLoad type="out" data={requestState.data?.weigthing[1]} />
                                                <WeigthTotal weigt={requestState.data?.request?.total_weigt} />
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        ) : (<></>)}

                    </div>
                </Container>
            </div>

            <ModalChangeStatus
                open={showModal}
                onClose={() => setShowModal(false)}
                onSave={handleAceptModal}
                type={requestState.data.request?.status_id[0]?.key} />
            <ModalOptionGeneric
                title={"¿Seguro quieres eliminar esta solicitud?"}
                message={"Recuerda que esta acción es permanente"}
                open={showModalDelete}
                onClose={() => setShowModalDelete(false)}
                onSave={() => handleAceptModalDelete()} />
        </>
    )
}

export default DetailRequest;


import React, { useState } from 'react';
import Select from 'react-select';

function SearchBar({ optionsFilter, onSearchButton }) {

  const [term, setTerm] = useState('');
  const [filter, setFilter] = useState('');
  const [errors, setErrors] = useState('');

  const onChangeTerm = (event) => {
    setTerm(event.target.value);
  }

  const onChangeFilter = (event) => {
    if (event.value === 'clean') {
      setErrors('');
      setTerm('');
      setFilter('');
      onSearchButton({ term: '', filter: '' });
    } else {
      setErrors('');
      setFilter(event);
    }
  }

  const handleSubmitSearch = (event) => {
    event.preventDefault();
    if (filter === '')
      setErrors('Este campo es obligatorio')
    else {
      setErrors('');
      onSearchButton({ term, filter: filter.value });
    }
  }

  return (
    <form>
      <div className="box-searchBar">
        <div className="box-search-bar-item">
          <input
            name="search"
            className="input-search-bar"
            type={filter?.value === 'created_at' ? 'date' : 'text'}
            onChange={onChangeTerm}
            value={term}
            placeholder="Búsqueda por placa de vehículo, conductor o fecha"
            onKeyPress={(e) => { e.key === 'Enter' && handleSubmitSearch(e); }} />
          <div>
            <Select
              name="filter"
              placeholder="Filtros"
              onChange={onChangeFilter}
              value={filter}
              options={optionsFilter} />
            {errors && (
              <p className="text-red-700  text-xs error-text" style={{ marginTop: '0px' }}>
                {errors}
              </p>
            )}
          </div>
        </div>
      </div>
    </form>
  )
}

export default SearchBar;
import ActionTypes from '../actionTypes';

const initialState = {
    user: null,
    isAuthenticated: false,
    error: false,
    message: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                user: {
                    email: action.payload.email,
                    token: action.payload.token,
                    name: action.payload.name,
                },
            };
        case ActionTypes.LOGIN_ERROR:
            return { ...state, error: true, message: action.payload.message };
        case ActionTypes.LOGOUT_SUCCESS:
            return { ...state, ...initialState };
        case ActionTypes.LOGOUT_ERROR:
            return { ...state, error: true, message: action.payload.message };
        default:
            return state;
    }
};

export default reducer;

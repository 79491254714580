import React, { useState, useEffect } from 'react'
import Header from '../../components/Header/Header';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Container } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
//images
import iconEdit from '../../images/edit-file.svg';
import noVehicule from '../../images/prohibido-el-paso.png';

// imports
import { auth, requests } from '../../redux/selectors';
import { getCant } from '../../redux/Requests/requests.actions';
import ListRequestsUser from '../ListRequestsUser';
import jwt_decode from "jwt-decode";

const Home = () => {

    const authState = useSelector(auth);
    const requestState = useSelector(requests);

    const [load, setLoad] = useState(false);

    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCant(history));
    }, []);

    useEffect(() => {
        setLoad(true);
    }, [requestState.cant]);

    return (
        <>
            <Header
                color="request"
                logo="logoBlue"
                colorName="black" />
            <div className="container">
                {load && (
                    requestState.cant === 0 && (
                        <Container maxWidth="md">
                            <div className="welcome-title"> <span> Bienvenid@, {authState.user.name}</span> </div>
                            <div className="boxWelcome">
                                <img src={iconEdit} alt="" />
                                <div className="welcome-content">
                                    <span>Aún no has solicitado ningun ingreso</span>
                                    <span>Ingresa y envia la siguiente información para generar una solicitud de ingreso al Puerto. </span>
                                </div>

                                <Link className="button-primary" to="/user/requests/new">Solicitar ingreso a puerto</Link>
                                <br></br>
                            </div>
                        </Container>
                    )
                )}
                {load && (
                    requestState.cant > 0 && (
                        <ListRequestsUser />
                    )
                )}
                <br></br>
            </div>
        </>
    )
}

export default Home;
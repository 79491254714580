import ActionTypes from '../actionTypes';
import { generateString } from '../../utils/Utils';

const initialState = {
    USERS: [],
    meta: {},
    error: false,
    message: null,
    id_log: '',
    data: {},
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.LOAD_USERS_SUCCESS:
            return { ...state, error: false, users: [...action.payload.users], meta: action.payload.meta };
        case ActionTypes.LOAD_USERS_ERROR:
            return { ...state, error: true, message: action.payload.message };
        default:
            return state;
    }
};

export default reducer;

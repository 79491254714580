import React from 'react';
import DataTable from 'react-data-table-component';
import { customStyles } from '../DataCRUD/config';

export default function DataReportCRUD({ data, columns, handleChangeSelect }) {

    return (
        <DataTable
            customStyles={customStyles}
            columns={columns}
            data={data}
            persistTableHead
            selectableRows
            onSelectedRowsChange={handleChangeSelect}
            noDataComponent={''}
        />
    );
}

import React from 'react'
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles, withStyles } from '@material-ui/core/styles';

// images
import iconVehiculoBarco from '../../images/vehiculo-puerta-barco.svg';

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 20,
        borderRadius: 20,
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        borderRadius: 20,
        backgroundColor: '#373F47;',
    },
}))(LinearProgress);

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        width: '100%',
        marginTop: '35px'
    },
});

export default function ProgressStatus(props) {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <img className={`vehicle-detail ${props.barValue === 8 ? 'one' : props.barValue === 50 ? 'two' : 'three'}`} src={iconVehiculoBarco} alt="Vehículo" />
            <BorderLinearProgress variant="determinate" value={props.barValue} />
        </div>
    );
}
import React from 'react'
import Header from '../../components/Header/Header';
import { Link } from 'react-router-dom';
import { Container, Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';

//images
import iconVehiculo from '../../images/vehiculo-puerta.svg';
import iconVehiculoBarco from '../../images/vehiculo-puerta-barco.svg';
import iconReporte from '../../images/reporte.svg';
import iconReportem from '../../images/reporte-m.svg';
import leftArrow from '../../images/left-arrow.svg';
import iconUser from '../../images/user.svg';
import iconList from '../../images/list.svg';

// imports
import { auth } from '../../redux/selectors';

const Home = () => {

    const authState = useSelector(auth);

    return (
        <>
            <Header />
            <div className="container">
                <Container maxWidth="md">
                    <div className="welcome-title" style={{ marginLeft: '0px' }}>
                        <span> Bienvenid@, {authState.user.name}</span>
                    </div>
                </Container>
                <Container maxWidth="md">
                    <Grid container spacing={6} >
                        <Grid Grid item xs={6}>
                            <div className="box-welcome-approve">
                                <div>
                                    <img src={iconVehiculo} alt="" />
                                    <img src={leftArrow} alt="" style={{ padding: '15px' }} />
                                    <img src={iconVehiculoBarco} alt="" />
                                </div>
                                <div className="welcome-content">
                                    <span>Vehículos en puerto</span>
                                </div>
                                <Link className="button-primary" to="/admin/requests/list">Ingresar</Link>
                            </div>
                        </Grid>
                        <Grid Grid item xs={6}>
                            <div className="box-welcome-approve">
                                <div>
                                    <img src={iconReporte} alt="" />
                                    <img src={leftArrow} alt="" style={{ padding: '15px' }} />
                                    <img src={iconReportem} alt="" />
                                </div>
                                <div className="welcome-content">
                                    <span>Reportes al Ministerio</span>
                                </div>
                                <Link className="button-primary" to="/admin/requests/report">Ingresar</Link>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={6} >
                        <Grid Grid item xs={3}></Grid>
                        <Grid Grid item xs={6}>
                            <div className="box-welcome-approve">
                                <div>
                                    <img src={iconUser} alt="icon user" />
                                    <img src={leftArrow} alt="" style={{ padding: '15px' }} />
                                    <img src={iconList} alt="icon list" />
                                </div>
                                <div className="welcome-content">
                                    <span>Usuarios</span>
                                </div>
                                <Link className="button-primary" to="/admin/users">Ingresar</Link>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </>
    )
}

export default Home;


import ActionTypes from '../actionTypes';
import axiosInstance from '../../helpers/axiosInstance';
import { startToast, stopToast } from '../Toast/toast.actions';
import { startLoading, stopLoading } from '../Loader/loader.actions';

export const getAllIncomeTypes =
    (history = null) =>
        (dispatch) => {
            dispatch(startLoading());
            axiosInstance(history, dispatch)
                .get('/income_types')
                .then((res) => {
                    const { data } = res;
                    dispatch(stopLoading());
                    return dispatch({
                        type: ActionTypes.LOAD_INCOME_TYPES_SUCCESS,
                        payload: {
                            income_types: data.data,
                        },
                    });
                })
                .catch((err) => {
                    const msg = err?.response?.message;
                    const message = msg || 'Error de conexión';
                    dispatch(stopLoading());
                    dispatch(startToast({ message, type: 'error' }));
                    return dispatch({
                        type: ActionTypes.LOAD_INCOME_TYPES_ERROR,
                        payload: {
                            message,
                        },
                    });
                });
        };
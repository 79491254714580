import ActionTypes from '../actionTypes';

const initialState = {
    income_types: [],
    error: false,
    message: null,
    id_log: '',
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.LOAD_INCOME_TYPES_SUCCESS:
            return { ...state, error: false, income_types: [...action.payload.income_types] };
        case ActionTypes.LOAD_INCOME_TYPES_ERROR:
            return { ...state, error: true, message: action.payload.message };
        default:
            return state;
    }
};

export default reducer;

import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Container,
  Switch,
  Radio,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Hidden,
} from "@material-ui/core";
import Select, { components } from "react-select";
import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

// imports
import { requests } from "../../redux/selectors";
import { operation_types } from "../../redux/selectors";
import { income_types } from "../../redux/selectors";
import { getAllOperationTypes } from "../../redux/OperationTypes/opetation_types.actions";
import { getAllIncomeTypes } from "../../redux/IncomeTypes/income_types.actions";
import {
  addRequest,
  updateRequest,
} from "../../redux/Requests/requests.actions";
import Header from "../../components/Header/Header.jsx";
import { getRequest } from "../../redux/Requests/requests.actions";

//images
import iconReturn from "../../images/return.svg";
import editFile from "../../images/edit-file-requests.svg";
import addFile from "../../images/add-image.svg";
import iconRemoveImage from "../../images/icon-remove-image.svg";
import iconPdf from "../../images/pdf.png";
import iconDoc from "../../images/doc.png";
import iconXls from "../../images/xls.png";
import iconAdjunto from "../../images/adjunto.png";

import ModalImage from "react-modal-image-responsive";
import { Filter1 } from "@material-ui/icons";

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};

export default function Requests({ name }) {
  const operationTypesState = useSelector(operation_types);
  const incomeTypesState = useSelector(income_types);
  const requestState = useSelector(requests);

  const [idEdit, setIdEdit] = useState();

  const history = useHistory();
  const dispatch = useDispatch();
  const search = useLocation().search;

  const [vehicleOwnershipCardBack, setVehicleOwnershipCardBack] = useState();
  const [vehicleOwnershipCardFront, setVehicleOwnershipCardFront] = useState();
  const [otherImage, setOtherImage] = useState([]);
  const [multiple, setMultiple] = useState();
  const [isEditForm, setIsEditForm] = useState(false);
  const [requiredFiles, setRequiredFiles] = useState(false);

  // const closeLightbox = () => {
  //     this.state.open = true;
  // };

  const vehicleOwnershipCardFrontChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setVehicleOwnershipCardFront(e.target.files[0]);
    }
  };

  const removeVehicleOwnershipCardFront = () => {
    setVehicleOwnershipCardFront();
    document.getElementById("vehicleOwnershipCardFront").value = null;
  };

  const vehicleOwnershipCardBackChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setVehicleOwnershipCardBack(e.target.files[0]);
    }
  };

  const removeVehicleOwnershipCardBack = () => {
    setVehicleOwnershipCardBack();
    document.getElementById("vehicleOwnershipCardBack").value = null;
  };

  const otherImageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const newTodo = [...otherImage];
      for (let w = 0; w < e.target.files.length; w++) {
        newTodo.push(e.target.files.item(w));
      }
      setOtherImage(newTodo);
    }
  };

  const removeOtherImage = (index) => {
    const newTodo = [...otherImage];
    newTodo.splice(index, 1);
    setOtherImage(newTodo);
    // document.getElementById('otherImage').value = null;
  };

  const handleChangeOperationType = (event) => {
    const operation = event.label.split(". ");
    if (operation[0] === "5" || operation[0] === "6" || operation[0] === "7") {
      setRequiredFiles(true);
    } else {
      setRequiredFiles(false);
    }
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    reset,
    getValues,
    setError,
  } = useForm();

  const goToBack = () => {
    history.goBack();
  };

  useEffect(() => {
    const idEdite = new URLSearchParams(search).get("idEdit");
    idEdite ? setIdEdit(idEdite) : setIdEdit();
  }, []);

  useEffect(() => {
    dispatch(getAllOperationTypes(history));
  }, []);

  useEffect(() => {
    dispatch(getAllIncomeTypes(history));
  }, []);

  useEffect(() => {
    if (idEdit) dispatch(getRequest(idEdit, history));
    else requestState.data = {};
  }, [idEdit]);

  useEffect(() => {
    if (
      requestState.data.request &&
      incomeTypesState.income_types.length > 0 &&
      operationTypesState.operation_types.length > 0
    ) {
      const income = incomeTypesState?.income_types.find(
        (it) => it.id === requestState.data?.request?.income_type_id
      );
      const operation = operationTypesState?.operation_types.find(
        (ot) => ot.id === requestState.data?.request?.operation_type_id
      );

      reset(requestState.data.request);

      setTimeout(() => {
        setValue("operation_type_id", {
          value: operation.id,
          label: `${operation.number}. ${operation.name}`,
        });
        setValue("income_type_id", {
          value: income.id,
          label: `${income.number}. ${income.name}`,
        });
        setValue("multiple", income.multiple === 1 ? "true" : "false");
      }, 1);

      setIsEditForm(true);
    }
  }, [
    requestState.data.request,
    incomeTypesState.income_types,
    operationTypesState.operation_types,
  ]);

  const create = async (values) => {
    let band = true;
    //##### tranporte privado no necesita nuevo de manifiesto ni envio al ministerio #####
    if (multiple && !requiredFiles) {
      const { authorization_number } = values;
      if (
        authorization_number.length < 9 ||
        !authorization_number.toString().startsWith("50")
      ) {
        band = false;
        setError(
          "authorization_number",
          {
            type: "manual",
            message: "No. Autorización no corresponde a una solicitud multiple",
          },
          {
            shouldFocus: true,
          }
        );
      }
    }

    if (band) {
      const {
        nit_transport_company,
        name_transport_company,
        vehicle_plate,
        date_multiple,
        authorization_number,
        conductor_name,
        conductor_identification,
        load_observations,
        income_type_id,
        operation_type_id,
      } = values;

      console.log("====================================");
      console.log(values);
      console.log("====================================");

      const formData = new FormData();
      // agregar imagenes
      formData.append("nit_transport_company", nit_transport_company);
      formData.append("name_transport_company", name_transport_company);
      formData.append("vehicle_plate", vehicle_plate);
      formData.append("multiple", multiple ? 1 : 0);
      if (date_multiple !== null)
        formData.append("date_multiple", date_multiple);
      formData.append("authorization_number", authorization_number);
      formData.append("conductor_name", conductor_name);
      formData.append("conductor_identification", conductor_identification);
      formData.append("load_observations", load_observations);
      formData.append("operation_type_id", operation_type_id.value);
      formData.append("income_type_id", income_type_id.value);
      formData.append(
        "vehicle_ownership_card_front",
        vehicleOwnershipCardFront
      );
      formData.append("vehicle_ownership_card_back", vehicleOwnershipCardBack);

      for (const file of otherImage) {
        formData.append("other_image", file);
      }
      if (idEdit)
        dispatch(
          updateRequest({
            id: requestState.data.request.id,
            formData,
            history,
          })
        );
      else
        dispatch(
          addRequest({
            id: 0,
            formData,
            history,
          })
        );
    }
  };
  const setMaxDate = (days) => {
    const date = new Date();
    date.setDate(date.getDate() + days);
    return date;
  }

  return (
    <>
      <Header color="request" logo="logoBlue" colorName="black" />
      <div className="container">
        <Container maxWidth="md">
          <div className="welcome-title">
            <img
              className="icon-return"
              src={iconReturn}
              alt=""
              onClick={() => goToBack()}
            />
            <span>Solicitar ingreso a puerto</span>
          </div>
        </Container>
        <Container maxWidth="md">
          <Grid container spacing={2}>
            <Grid Grid item xs={3}>
              <div className="requests-info">
                <img src={editFile} alt="" />
                <p>
                  Ingresa y envia la siguiente información para que tu vehículo
                  ingrese a nuestro sistema y se solicite un ingreso al Puerto.{" "}
                </p>
              </div>
            </Grid>
            <Grid Grid item xs={9}>
              <div className="box-form-requests">
                <form onSubmit={handleSubmit(create)}>
                  <Grid container spacing={1} className="select-requests">
                    <Grid Grid item xs={12}>
                      <Controller
                        name="income_type_id"
                        control={control}
                        {...register("income_type_id", {
                          required: {
                            value: true,
                            message: "Este campo es requerido",
                          },
                        })}
                        render={({ field }) => (
                          <Select
                            {...field}
                            components={{
                              ValueContainer: CustomValueContainer,
                            }}
                            placeholder={"Tipo de Ingreso"}
                            styles={{
                              container: (provided, state) => ({
                                ...provided,
                                marginTop: 50,
                              }),
                              valueContainer: (provided, state) => ({
                                ...provided,
                                overflow: "visible",
                              }),
                              placeholder: (provided, state) => ({
                                ...provided,
                                background: "#ffffff",
                                padding: "0 10px",
                                position: "absolute",
                                top:
                                  state.hasValue || state.selectProps.inputValue
                                    ? -15
                                    : "10%",
                                transition: "top 0.1s, font-size 0.1s",
                                fontSize:
                                  (state.hasValue ||
                                    state.selectProps.inputValue) &&
                                  13,
                              }),
                            }}
                            options={incomeTypesState?.income_types.map(
                              (v) => ({
                                value: v.id,
                                label: `${v.number}. ${v.name}`,
                              })
                            )}
                            isClearable
                            isSearchable
                            isRequired
                          />
                        )}
                      />
                      {errors.income_type_id && (
                        <p
                          className="text-red-700  text-xs error-text"
                          style={{ marginTop: "0px" }}
                        >
                          {errors.income_type_id.message}
                        </p>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className="select-requests">
                    <Grid Grid item xs={12}>
                      <Controller
                        name="operation_type_id"
                        control={control}
                        {...register("operation_type_id", {
                          required: {
                            value: true,
                            message: "Este campo es requerido",
                          },
                        })}
                        render={({ field }) => (
                          <Select
                            {...field}
                            components={{
                              ValueContainer: CustomValueContainer,
                            }}
                            placeholder={"Tipo de Operación"}
                            styles={{
                              container: (provided, state) => ({
                                ...provided,
                                marginTop: 50,
                              }),
                              valueContainer: (provided, state) => ({
                                ...provided,
                                overflow: "visible",
                              }),
                              placeholder: (provided, state) => ({
                                ...provided,
                                background: "#ffffff",
                                padding: "0 10px",
                                position: "absolute",
                                top:
                                  state.hasValue || state.selectProps.inputValue
                                    ? -15
                                    : "",
                                transition: "top 0.1s, font-size 0.1s",
                                fontSize:
                                  (state.hasValue ||
                                    state.selectProps.inputValue) &&
                                  13,
                              }),
                            }}
                            options={operationTypesState?.operation_types.map(
                              (v) => ({
                                value: v.id,
                                label: `${v.number}. ${v.name}`,
                              })
                            )}
                            onChange={(e) => {
                              field.onChange(e);
                              handleChangeOperationType(e);
                            }}
                            isClearable
                            isSearchable
                          />
                        )}
                      />
                      {errors.operation_type_id && (
                        <p
                          className="text-red-700  text-xs error-text"
                          style={{ marginTop: "0px" }}
                        >
                          {errors.operation_type_id.message}
                        </p>
                      )}
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container spacing={1} className="select-requests">
                    <Grid Grid item xs={6}>
                      <Grid container spacing={1}>
                        <Grid Grid item xs={6}>
                          <div
                            style={{
                              color: "#757575",
                              paddingTop: "8px",
                            }}
                          >
                            {" "}
                            Solicitud Multiple
                          </div>
                        </Grid>
                        <Grid Grid item xs={6}>
                          <Controller
                            name="multiple"
                            control={control}
                            {...register("multiple", {
                              required: {
                                value: true,
                                message: "Este campo es requerido",
                              },
                            })}
                            render={({ field }) => (
                              <RadioGroup
                                {...field}
                                row
                                aria-label="position"
                                name="multiple"
                              >
                                <FormControlLabel
                                  value="true"
                                  control={
                                    <Radio
                                      color="primary"
                                      onChange={() => setMultiple(true)}
                                    />
                                  }
                                  label="SI"
                                />
                                <FormControlLabel
                                  value="false"
                                  control={
                                    <Radio
                                      color="primary"
                                      onChange={() => setMultiple(false)}
                                    />
                                  }
                                  label="NO"
                                />
                              </RadioGroup>
                            )}
                          />
                          {errors.multiple && (
                            <p
                              className="text-red-700  text-xs error-text"
                              style={{ marginTop: "0px" }}
                            >
                              {errors.multiple.message}
                            </p>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    {multiple && (
                      <>
                        <Grid Grid item xs={2}></Grid>
                        <Grid Grid item xs={4}>
                          <TextField
                            {...register("date_multiple", {
                              required: {
                                value: true,
                                message: "Este campo es requerido",
                              },
                            })}
                            name="date_multiple"
                            type="date"
                            size="small"
                            variant="outlined"
                            label="Fecha"
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              inputProps: {
                                min: setMaxDate(-1).toISOString().split("T")[0],
                                max: setMaxDate(6).toISOString().split("T")[0],
                              },
                            }}
                          />
                          {errors.date_multiple && (
                            <p
                              className="text-red-700  text-xs error-text"
                              style={{ marginTop: "0px" }}
                            >
                              {errors.date_multiple.message}
                            </p>
                          )}
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <br />
                  <Grid container spacing={2}>
                    <Grid Grid item xs={12}>
                      <TextField
                        {...register("nit_transport_company", {
                          required: {
                            value: true,
                            message: "Este campo es requerido",
                          },
                          pattern: {
                            value: /^[0-9,$]*$/i,
                            message: "Solo valores numéricos",
                          },
                          minLength: {
                            value: 10,
                            message: "Nit debe contener 10 dígitos",
                          },
                          maxLength: {
                            value: 10,
                            message: "Nit debe contener 10 dígitos",
                          },
                        })}
                        label={'margin="normal"'}
                        size="small"
                        variant="outlined"
                        fullWidth
                        id="nit_transport_company"
                        name="nit_transport_company"
                        label="NIT empresa transportadora"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      {errors.nit_transport_company && (
                        <p
                          className="text-red-700  text-xs error-text"
                          style={{ marginTop: "0px" }}
                        >
                          {errors.nit_transport_company.message}
                        </p>
                      )}
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container spacing={1} className="select-requests">
                    <Grid Grid item xs={12}>
                      <TextField
                        {...register("name_transport_company", {
                          required: {
                            value: true,
                            message: "Este campo es requerido",
                          },
                        })}
                        label={'margin="normal"'}
                        size="small"
                        variant="outlined"
                        fullWidth
                        id="name_transport_company"
                        name="name_transport_company"
                        label="Nombre empresa transportadora"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      {errors.name_transport_company && (
                        <p
                          className="text-red-700  text-xs error-text"
                          style={{ marginTop: "0px" }}
                        >
                          {errors.name_transport_company.message}
                        </p>
                      )}
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container spacing={2} className="select-requests">
                    <Grid Grid item xs={6}>
                      <TextField
                        size="small"
                        className="input-request"
                        variant="outlined"
                        fullWidth
                        id="vehicle_plate"
                        name="vehicle_plate"
                        label="Placa vehículo"
                        onInput={(e) =>
                          (e.target.value = ("" + e.target.value).toUpperCase())
                        }
                        {...register("vehicle_plate", {
                          required: {
                            value: true,
                            message: "Este campo es requerido",
                          },
                          minLength: {
                            value: 6,
                            message: "Debe tener mínimo 6 carácteres",
                          },
                        })}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      {errors.vehicle_plate && (
                        <p
                          className="text-red-700  text-xs error-text"
                          style={{ marginTop: "0px" }}
                        >
                          {errors.vehicle_plate.message}
                        </p>
                      )}
                    </Grid>
                    <Grid Grid item xs={6}>
                      <TextField
                        size="small"
                        variant="outlined"
                        fullWidth
                        id="authorization_number"
                        name="authorization_number"
                        label="No. Autorización"
                        {...register("authorization_number", {
                          required: {
                            value: !requiredFiles,
                            message: "Este campo es requerido",
                          },
                          pattern: {
                            value: /^[0-9,$]*$/i,
                            message: "Solo valores numéricos",
                          },
                          minLength: {
                            value: 8,
                            message: "Debe tener mínimo 8 carácteres",
                          },
                        })}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      {errors.authorization_number && (
                        <p
                          className="text-red-700  text-xs error-text"
                          style={{ marginTop: "0px" }}
                        >
                          {errors.authorization_number.message}
                        </p>
                      )}
                    </Grid>
                    {/* <Grid Grid item xs={4}>
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                fullWidth
                                                id="manifest_number"
                                                name="manifest_number"
                                                label="No. Manifiesto"
                                                {...register('manifest_number', {
                                                    required: {
                                                        value: true,
                                                        message: 'Este campo es requerido',
                                                    },
                                                    minLength: {
                                                        value: 6,
                                                        message: 'Debe tener mínimo 6 carácteres',
                                                    },
                                                })}
                                            />
                                            {errors.manifest_number && (
                                                <p className="text-red-700  text-xs error-text" style={{ marginTop: '0px' }}>
                                                    {errors.manifest_number.message}
                                                </p>
                                            )}
                                        </Grid> */}
                  </Grid>
                  <br />
                  <Grid container spacing={2} className="select-requests">
                    <Grid Grid item xs={6}>
                      <TextField
                        size="small"
                        variant="outlined"
                        fullWidth
                        id="conductor_name"
                        name="conductor_name"
                        label="Nombre conductor"
                        {...register("conductor_name", {
                          required: {
                            value: true,
                            message: "Este campo es requerido",
                          },
                        })}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      {errors.conductor_name && (
                        <p
                          className="text-red-700  text-xs error-text"
                          style={{ marginTop: "0px" }}
                        >
                          {errors.conductor_name.message}
                        </p>
                      )}
                    </Grid>
                    <Grid Grid item xs={6}>
                      <TextField
                        size="small"
                        variant="outlined"
                        fullWidth
                        id="conductor_identification"
                        name="conductor_identification"
                        label="Cédula conductor – sin puntos"
                        {...register("conductor_identification", {
                          required: {
                            value: true,
                            message: "Este campo es requerido",
                          },
                          minLength: {
                            value: 6,
                            message: "Debe tener mínimo 6 carácteres",
                          },
                        })}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      {errors.conductor_identification && (
                        <p
                          className="text-red-700  text-xs error-text"
                          style={{ marginTop: "0px" }}
                        >
                          {errors.conductor_identification.message}
                        </p>
                      )}
                    </Grid>
                  </Grid>
                  <br />
                  {/* <Grid container spacing={2}>
                                        <Grid Grid item xs={12} >
                                            <TextField
                                                label={'margin="normal"'}
                                                size="small"
                                                variant="outlined"
                                                fullWidth
                                                id="load"
                                                name="load"
                                                label="Carga"
                                                {...register('load', {
                                                    required: {
                                                        value: true,
                                                        message: 'Este campo es requerido',
                                                    },
                                                })}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                            {errors.load && (
                                                <p className="text-red-700  text-xs error-text" style={{ marginTop: '0px' }}>
                                                    {errors.load.message}
                                                </p>
                                            )}
                                        </Grid>
                                    </Grid> */}
                  <Grid container spacing={2} className="select-requests">
                    <Grid Grid item xs={12}>
                      <TextField
                        label={'margin="normal"'}
                        size="small"
                        variant="outlined"
                        fullWidth
                        multiline
                        id="load_observations"
                        rows={4}
                        name="load_observations"
                        label="Carga / Observaciones"
                        {...register("load_observations")}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <br />
                  <div className="requests-info">
                    <p>
                      Adjunta la imagen de la Tarjeta de propiedad del vehiculo*
                    </p>
                  </div>
                  <Grid container spacing={2} className="select-requests">
                    <Grid Grid item xs={6}>
                      <div className="title-image">Frente</div>
                      <div className="requests-udpload">
                        <input
                          {...register("vehicle_ownership_card_front", {
                            required: {
                              value: requiredFiles,
                              message: "Este campo es requerido",
                            },
                          })}
                          name="vehicle_ownership_card_front"
                          id="vehicleOwnershipCardFront"
                          accept="image/*,.pdf, .doc, .docx"
                          type="file"
                          onChange={vehicleOwnershipCardFrontChange}
                        />
                        {vehicleOwnershipCardFront && (
                          <>
                            {vehicleOwnershipCardFront.name.split(".")[
                              vehicleOwnershipCardFront.name.split(".").length -
                              1
                            ] === "png" ||
                              vehicleOwnershipCardFront.name.split(".")[
                              vehicleOwnershipCardFront.name.split(".").length -
                              1
                              ] === "jpg" ||
                              vehicleOwnershipCardFront.name.split(".")[
                              vehicleOwnershipCardFront.name.split(".").length -
                              1
                              ] === "webp" ||
                              vehicleOwnershipCardFront.name.split(".")[
                              vehicleOwnershipCardFront.name.split(".").length -
                              1
                              ] === "jpeg" ? (
                              <Grid
                                container
                                spacing={10}
                                style={{ paddingLeft: "20px" }}
                              >
                                <Grid Grid item xs={7}>
                                  <ModalImage
                                    className="requests-udpload-image"
                                    hideZoom="false"
                                    hideDownload="true"
                                    small={URL.createObjectURL(
                                      vehicleOwnershipCardFront
                                    )}
                                    large={URL.createObjectURL(
                                      vehicleOwnershipCardFront
                                    )}
                                    alt={vehicleOwnershipCardFront.name}
                                  />
                                </Grid>
                                <Grid Grid item xs={5}>
                                  <button
                                    className="button-delete-image"
                                    onClick={removeVehicleOwnershipCardFront}
                                  >
                                    <img
                                      src={iconRemoveImage}
                                      alt="Eliminar imágen"
                                    />
                                    <span>Eliminar imagen</span>
                                  </button>
                                </Grid>
                              </Grid>
                            ) : vehicleOwnershipCardFront.name.split(".")[
                              vehicleOwnershipCardFront.name.split(".")
                                .length - 1
                            ] === "pdf" ? (
                              <div>
                                <img
                                  src={iconPdf}
                                  className="requests-udpload-image"
                                  alt="Eliminar imágen"
                                  onClick={() => { }}
                                />
                                <div className="icon-delete-requests-udpload">
                                  <img
                                    src={iconRemoveImage}
                                    alt="Eliminar imágen"
                                    onClick={removeVehicleOwnershipCardFront}
                                  />
                                </div>
                              </div>
                            ) : vehicleOwnershipCardFront.name.split(".")[
                              vehicleOwnershipCardFront.name.split(".")
                                .length - 1
                            ] === "doc" ||
                              vehicleOwnershipCardFront.name.split(".")[
                              vehicleOwnershipCardFront.name.split(".")
                                .length - 1
                              ] === "docx" ? (
                              <div>
                                <img
                                  src={iconDoc}
                                  className="requests-udpload-image"
                                  alt="Eliminar imágen"
                                  onClick={() => { }}
                                />
                                <div className="icon-delete-requests-udpload">
                                  <img
                                    src={iconRemoveImage}
                                    alt="Eliminar imágen"
                                    onClick={removeVehicleOwnershipCardFront}
                                  />
                                </div>
                              </div>
                            ) : (
                              <div>
                                <img
                                  src={iconAdjunto}
                                  className="requests-udpload-image"
                                  alt="Eliminar imágen"
                                  onClick={() => { }}
                                />
                                <div className="icon-delete-requests-udpload">
                                  <img
                                    src={iconRemoveImage}
                                    alt="Eliminar imágen"
                                    onClick={removeVehicleOwnershipCardFront}
                                  />
                                </div>
                              </div>
                            )}
                          </>
                        )}
                        {!vehicleOwnershipCardFront && (
                          <>
                            <img src={addFile} alt="" />
                            <a
                              onClick={() =>
                                document
                                  .getElementById("vehicleOwnershipCardFront")
                                  .click()
                              }
                              rel="noopener noreferrer"
                            >
                              Seleccióna el documento desde tu dispositivo
                            </a>
                          </>
                        )}
                        {errors.vehicle_ownership_card_front && (
                          <p
                            className="text-red-700  text-xs error-text"
                            style={{ marginTop: "0px" }}
                          >
                            {errors.vehicle_ownership_card_front.message}
                          </p>
                        )}
                      </div>
                    </Grid>
                    <Grid Grid item xs={6}>
                      <div className="title-image">Reverso</div>
                      <div className="requests-udpload">
                        <input
                          {...register("vehicle_ownership_card_back", {
                            required: {
                              value: requiredFiles,
                              message: "Este campo es requerido",
                            },
                          })}
                          name="vehicle_ownership_card_back"
                          id="vehicleOwnershipCardBack"
                          accept="image/*,.pdf, .doc, .docx"
                          type="file"
                          onChange={vehicleOwnershipCardBackChange}
                        />
                        {vehicleOwnershipCardBack && (
                          <>
                            {vehicleOwnershipCardBack.name.split(".")[
                              vehicleOwnershipCardBack.name.split(".").length -
                              1
                            ] === "png" ||
                              vehicleOwnershipCardBack.name.split(".")[
                              vehicleOwnershipCardBack.name.split(".").length -
                              1
                              ] === "jpg" ||
                              vehicleOwnershipCardBack.name.split(".")[
                              vehicleOwnershipCardBack.name.split(".").length -
                              1
                              ] === "webp" ||
                              vehicleOwnershipCardBack.name.split(".")[
                              vehicleOwnershipCardBack.name.split(".").length -
                              1
                              ] === "jpeg" ? (
                              <Grid
                                container
                                spacing={10}
                                style={{ paddingLeft: "20px" }}
                              >
                                <Grid Grid item xs={7}>
                                  <ModalImage
                                    className="requests-udpload-image"
                                    hideZoom="false"
                                    hideDownload="true"
                                    small={URL.createObjectURL(
                                      vehicleOwnershipCardBack
                                    )}
                                    large={URL.createObjectURL(
                                      vehicleOwnershipCardBack
                                    )}
                                    alt={vehicleOwnershipCardBack.name}
                                  />
                                </Grid>
                                <Grid Grid item xs={5}>
                                  <button
                                    className="button-delete-image"
                                    onClick={removeVehicleOwnershipCardBack}
                                  >
                                    <img
                                      src={iconRemoveImage}
                                      alt="Eliminar imágen"
                                    />
                                    <span>Eliminar imagen</span>
                                  </button>
                                </Grid>
                              </Grid>
                            ) : vehicleOwnershipCardBack.name.split(".")[
                              vehicleOwnershipCardBack.name.split(".")
                                .length - 1
                            ] === "pdf" ? (
                              <div>
                                <img
                                  src={iconPdf}
                                  className="requests-udpload-image"
                                  alt="Eliminar imágen"
                                  onClick={() => { }}
                                />
                                <div className="icon-delete-requests-udpload">
                                  <img
                                    src={iconRemoveImage}
                                    alt="Eliminar imágen"
                                    onClick={removeVehicleOwnershipCardBack}
                                  />
                                </div>
                              </div>
                            ) : vehicleOwnershipCardBack.name.split(".")[
                              vehicleOwnershipCardBack.name.split(".")
                                .length - 1
                            ] === "doc" ||
                              vehicleOwnershipCardBack.name.split(".")[
                              vehicleOwnershipCardBack.name.split(".")
                                .length - 1
                              ] === "docx" ? (
                              <div>
                                <img
                                  src={iconDoc}
                                  className="requests-udpload-image"
                                  alt="Eliminar imágen"
                                  onClick={() => { }}
                                />
                                <div className="icon-delete-requests-udpload">
                                  <img
                                    src={iconRemoveImage}
                                    alt="Eliminar imágen"
                                    onClick={removeVehicleOwnershipCardBack}
                                  />
                                </div>
                              </div>
                            ) : (
                              <div>
                                <img
                                  src={iconAdjunto}
                                  className="requests-udpload-image"
                                  alt="Eliminar imágen"
                                  onClick={() => { }}
                                />
                                <div className="icon-delete-requests-udpload">
                                  <img
                                    src={iconRemoveImage}
                                    alt="Eliminar imágen"
                                    onClick={removeVehicleOwnershipCardBack}
                                  />
                                </div>
                              </div>
                            )}
                          </>
                        )}
                        {!vehicleOwnershipCardBack && (
                          <>
                            <img src={addFile} alt="" />
                            <a
                              onClick={() =>
                                document
                                  .getElementById("vehicleOwnershipCardBack")
                                  .click()
                              }
                              rel="noopener noreferrer"
                            >
                              Seleccióna el documento desde tu dispositivo
                            </a>
                          </>
                        )}
                        {errors.vehicle_ownership_card_back && (
                          <p
                            className="text-red-700  text-xs error-text"
                            style={{ marginTop: "0px" }}
                          >
                            {errors.vehicle_ownership_card_back.message}
                          </p>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className="select-requests">
                    <Grid Grid item xs={otherImage?.length !== 0 ? 9 : 12}>
                      <div className="requests-info">
                        <p>
                          Adjunta los documentos que consideres necesarios (
                          cedúla, tarjeta de propiedad, ARL o seguridad social,
                          etc )
                        </p>
                        <div className="requests-udpload">
                          <input
                            id="other_image"
                            accept="*"
                            name="other_image"
                            multiple
                            type="file"
                            onChange={otherImageChange}
                            style={{ display: "none" }}
                          />
                          {otherImage.length > 0 && (
                            <>
                              <Grid
                                container
                                item
                                xs={10}
                                style={{
                                  paddingLeft: "10px",
                                  paddingRight: "20px",
                                }}
                              >
                                {/* <Grid Grid item xs={3} ></Grid> */}
                                {otherImage.map((item, index) => {
                                  let imageSelect = item;
                                  let items = item.name.split(".");
                                  var extencion = items[items.length - 1];
                                  extencion = extencion?.toLowerCase();
                                  if (
                                    extencion === "png" ||
                                    extencion === "jpg" ||
                                    extencion === "jpeg"
                                  ) {
                                    return (
                                      <div key={index}>
                                        <ModalImage
                                          className="requests-udpload-image"
                                          hideZoom="false"
                                          hideDownload="true"
                                          small={URL.createObjectURL(item)}
                                          large={URL.createObjectURL(item)}
                                          alt={item.name}
                                        />
                                        <div className="icon-delete-requests-udpload">
                                          <img
                                            src={iconRemoveImage}
                                            alt="Eliminar imágen"
                                            onClick={() =>
                                              removeOtherImage(index)
                                            }
                                          />
                                        </div>
                                      </div>
                                    );
                                  } else if (extencion === "pdf") {
                                    return (
                                      <div key={index}>
                                        <img
                                          src={iconPdf}
                                          className="requests-udpload-image"
                                          alt="Eliminar imágen"
                                          onClick={() => { }}
                                        />
                                        <div className="icon-delete-requests-udpload">
                                          <img
                                            src={iconRemoveImage}
                                            alt="Eliminar imágen"
                                            onClick={() =>
                                              removeOtherImage(index)
                                            }
                                          />
                                        </div>
                                      </div>
                                    );
                                  } else if (extencion === "docx") {
                                    return (
                                      <div key={index}>
                                        <img
                                          src={iconDoc}
                                          className="requests-udpload-image"
                                          alt="Eliminar imágen"
                                          onClick={() => { }}
                                        />
                                        <div className="icon-delete-requests-udpload">
                                          <img
                                            src={iconRemoveImage}
                                            alt="Eliminar imágen"
                                            onClick={() =>
                                              removeOtherImage(index)
                                            }
                                          />
                                        </div>
                                      </div>
                                    );
                                  } else if (extencion === "xlsx") {
                                    return (
                                      <div key={index}>
                                        <img
                                          src={iconXls}
                                          className="requests-udpload-image"
                                          alt="Eliminar imágen"
                                          onClick={() => { }}
                                        />
                                        <div className="icon-delete-requests-udpload">
                                          <img
                                            src={iconRemoveImage}
                                            alt="Eliminar imágen"
                                            onClick={() =>
                                              removeOtherImage(index)
                                            }
                                          />
                                        </div>
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <div key={index}>
                                        <img
                                          src={iconAdjunto}
                                          className="requests-udpload-image"
                                          alt="Eliminar imágen"
                                          onClick={() => { }}
                                        />
                                        <div className="icon-delete-requests-udpload">
                                          <img
                                            src={iconRemoveImage}
                                            alt="Eliminar imágen"
                                            onClick={() =>
                                              removeOtherImage(index)
                                            }
                                          />
                                        </div>
                                      </div>
                                    );
                                  }
                                })}
                              </Grid>
                            </>
                          )}
                          {otherImage?.length === 0 && (
                            <>
                              <img src={addFile} alt="" />
                              <a
                                onClick={() =>
                                  document.getElementById("other_image").click()
                                }
                                rel="noopener noreferrer"
                              >
                                Seleccióna el documento desde tu dispositivo
                              </a>
                            </>
                          )}
                        </div>
                      </div>
                    </Grid>
                    {otherImage?.length !== 0 && (
                      <Grid
                        Grid
                        item
                        xs={3}
                        className="requests-udpload-text"
                        style={{ paddingTop: "60px" }}
                      >
                        <img src={addFile} alt="" />
                        <a
                          onClick={() =>
                            document.getElementById("other_image").click()
                          }
                          rel="noopener noreferrer"
                        >
                          Agregar mas archivos
                        </a>
                      </Grid>
                    )}
                  </Grid>
                  <button
                    className="button-primary-request"
                    variant="contained"
                    fullWidth
                    type="submit"
                  >
                    Enviar información y solicitar ingreso
                  </button>
                </form>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}

import React from 'react'
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import ModalGeneric from '../modal/modalGeneric';

import { Grid } from '@material-ui/core';

// images
import iconNoApproved from '../../images/no-approved.svg';
import { deleteRequest, retryMinistryRequest } from '../../redux/Requests/requests.actions';


export default function StatusApproved(props) {
    const [showModal, setShowModal] = React.useState(false);
    const dispatch = useDispatch();
    const history = useHistory();

    const handleAceptModal = (type) => {
        setShowModal(false);
        onSubmit(props)
    }

    const onSubmit = async (props) => dispatch(deleteRequest(props.id, history));

    const onRetryMinistryRequest = async () => dispatch(retryMinistryRequest(props.id, history));

    return (
        <div className="box-welcome-status-approve">
            <>
                <img src={iconNoApproved} alt="icon-error" />
                <div className="approved-title">
                    <p>{props.response_min}</p>
                </div>
                <span className="approved-date">{moment(props.request_date).format("DD/MM/YYYY")}</span>
                <div className="approved-content">
                    <p>Actualiza la información y vuelve a enviar al ministerio o elimina el registro del vehículo.</p>
                </div>
                <br />
                <div style={{ width: '80%' }}>
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item>
                            <p className="button-primary button-longer button-send" onClick={onRetryMinistryRequest}>
                                Enviar nuevamente al ministerio
                            </p>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item xs={5}>
                            <Link className="button-sencondary button-edit" to={`/admin/requests/new?idEdit=${props.id}`}>
                                Editar
                            </Link>
                        </Grid>
                        <Grid item xs={5}>
                            <p className="button-sencondary button-delete" onClick={() => { setShowModal(true) }}>
                                Eliminar
                            </p>
                        </Grid>
                    </Grid>
                </div>
                {/* <div>
                    <div>
                        <p className="button-primary button-send" onClick={onRetryMinistryRequest}>
                            Enviar al ministerio
                        </p>
                    </div>
                    <div>
                        <Link className="button-sencondary button-edit" to={`/admin/requests/new?idEdit=${props.id}`}>
                            Editar
                        </Link>
                        <p className="button-sencondary button-delete" onClick={() => { setShowModal(true) }}>
                            Eliminar
                        </p>
                    </div>
                </div> */}
            </>
            <ModalGeneric
                title={"¿Seguro quieres eliminar esta solicitud?"}
                message={"Recuerda que esta acción es permanente"}
                open={showModal}
                onClose={() => setShowModal(false)}
                onSave={handleAceptModal}
            />
        </div>
    )
}

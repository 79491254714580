import { useState, useEffect } from "react";
import { Container } from '@material-ui/core';
import moment from 'moment';
import Select from 'react-select';
import Pagination from '@material-ui/lab/Pagination';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';

//importaciones
import { users } from "../../redux/selectors";
import { getAllUsers } from "../../redux/Users/users.actions";
import Header from '../../components/Header/Header';
import DataCRUD from '../../components/DataCRUD';
import SearchBar from '../../components/SearchBar';

//images
import iconReturn from '../../images/return.svg';

const CustomTab = withStyles({
    root: {
        textTransform: "none",
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '20px',
        lineHeight: '24px',
        color: '#373F47',
        opacity: '0.3',

        "&.Mui-selected": {
            color: '#036BA2',
        }
    },
})(Tab);

const columnsModel = [
    {
        name: 'Nombre y apellido del usuario',
        selector: 'name',
        cell: (row) => row?.name,
        sortable: true,
    },
    {
        name: 'Cedula',
        selector: 'document',
        cell: (row) => row?.document,
        sortable: true,
    },
    {
        name: 'Rol',
        selector: 'role',
        cell: (row) => row?.role_users[0]?.role?.description,
        sortable: true,
    },
    {
        name: 'Cliente',
        selector: 'client',
        cell: (row) => row?.client,
        sortable: true,
    }
];

const optionsFilter = [
    { value: 'clean', label: 'Limpiar' },
    { value: 'name', label: 'Nombre y apellido' },
    { value: 'role', label: 'Rol' },
    { value: 'client', label: 'Cliente' }
]

const Users = () => {

    const usersState = useSelector(users);
    const [sort, setSort] = useState('name DESC');
    const [search, setSearch] = useState('');
    const [tabValue, setTabValue] = useState(0);
    const [roleSearch, setRoleRearch] = useState('');

    const [paginator, setPaginator] = useState({
        totalPages: 0,
        limit: 5,
        page: 1
    });

    const history = useHistory();
    const dispatch = useDispatch();

    const handleChangePage = (event, value) => {
        setPaginator({
            ...paginator,
            page: value
        });
    };

    useEffect(() => {
        dispatch(getAllUsers(search, paginator.page, paginator.limit, sort, history));
    }, [paginator.page, sort, search]);

    useEffect(() => {
        setPaginator({
            ...paginator,
            totalPages: Math.ceil(usersState.meta.total / paginator.limit)
        });
    }, [usersState.users]);

    const handleChangeTab = (event, value) => {
        setTabValue(value);
    }

    const sortFunction = (column, sortDirection) => {
        setSort(`${column.sortField} ${sortDirection}`);
        setPaginator({
            ...paginator,
            page: 1
        })
    }

    const onSearchButton = (values) => {
        const { filter, term } = values;
        if (filter && term)
            setSearch(`${filter}=${term}`);
        else
            setSearch('');

        setPaginator({
            ...paginator,
            page: 1
        })
    }

    const goToBack = () => {
        history.push('/admin');
    }

    const handleGoToNew = () => {
        history.push('/admin/users/detail/edit/');
    }

    return (
        <>
            <Header />
            <div className="container">
                <Container maxWidth="md">
                    <div className="welcome-title">
                        <img className="icon-return" src={iconReturn} alt="" onClick={() => goToBack()} />
                        <span>Usuarios</span>
                        <button className="button-primary-request" variant="contained" fullWidth onClick={() => history.push('/admin/users/created')}>
                            Crear nuevo usuario
                        </button>
                    </div>
                </Container>
                {/* <SearchBar onSearchButton={onSearchButton} optionsFilter={optionsFilter} /> */}
                <Container maxWidth="md">
                    {/* <div className="box-welcome-approve"> */}
                    {/* <div className="box-search"> */}
                    <SearchBar onSearchButton={onSearchButton} optionsFilter={optionsFilter} />
                    {/* <input className="input-search" type="search" name="" id="" onChange={onSearchValueChange} placeholder="Búsqueda por nombre, empresa" />
                            <div>
                                <Select placeholder="Filtros" options={[]} />
                            </div> */}
                    {/* </div> */}
                    {/* </div> */}
                    <Tabs
                        style={{ paddingTop: '50px' }}
                        variant="standard"
                        value={tabValue}
                        onChange={handleChangeTab}
                        TabIndicatorProps={{
                            style: { height: "0px", }
                        }}>
                        <CustomTab label="Usuarios" /* onClick={() => handleChangeRole('user')} */ />
                        {/* <CustomTab label="Vigilantes" onClick={() => handleChangeRole('vigilant')} /> */}
                    </Tabs>
                    <DataCRUD
                        url="/admin/users/detail"
                        data={usersState.users}
                        columns={columnsModel}
                        sortFunction={sortFunction}
                    />
                    {paginator.totalPages > 1 && (
                        <div className="paginator">
                            <Pagination
                                hidePrevButton
                                hideNextButton
                                count={paginator.totalPages}
                                page={paginator.page}
                                onChange={handleChangePage} />
                        </div>
                    )}
                </Container>
            </div>
        </>
    )
}
export default Users;
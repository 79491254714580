import React, { useEffect } from 'react'
import Header from '../../components/Header/Header';
import { Container, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import Pagination from '@material-ui/lab/Pagination';

//images
import iconReturn from '../../images/return.svg';
import arrowupRight from '../../images/arrow-up-right.svg'

// imports
import { requests } from '../../redux/selectors';
import { getAllRequestsReport } from '../../redux/Requests/requests.actions';
import DataReportCRUD from '../../components/DataReportCRUD';
import { useState } from 'react';
import ExportReport from './Export';
import SearchBar from '../../components/SearchBar';

const columnsModel = [
    {
        name: 'Terminal portuario',
        selector: (row) => row?.code_port,
    },
    {
        name: 'Sistema de  Enturnamiento',
        selector: (row) => row?.income_type.number,
        sortable: true,
    },
    {
        name: 'Tipo de operación',
        selector: (row) => row?.operation_type.number,
        sortable: true,
    },
    {
        name: 'FECHA OFERTA SOLICITUD',
        selector: (row) => moment(row?.created_at).format('DD/MM/YYYY'),
        sortable: true,
    },
    {
        name: 'empresa transportadora',
        selector: (row) => row?.nit_transport_company,
        sortable: true,
    },
    {
        name: 'VEHICULO NUM.',
        selector: (row) => row?.vehicle_plate,
        sortable: true,
    },
    {
        name: 'CONDUCTOR',
        selector: (row) => row?.conductor_name,
        sortable: true,
    },
    {
        name: 'CONDUCTOR CEDULA',
        selector: (row) => row?.conductor_identification,
        sortable: true,
    },
    {
        name: 'Nun. Manifiesto',
        selector: (row) => row?.manifest_number,
        sortable: true,
    },
    {
        name: 'Nun. Autorizacion',
        selector: (row) => row?.authorization_number,
        sortable: true,
    },
    {
        name: 'FECHA DE ENTRADA',
        selector: (row) => moment(row?.weigths[0].date).format('DD/MM/YYYY'),
        sortable: true,
    },
    {
        name: 'FECHA DE SALIDA',
        selector: (row) => moment(row?.weigths[1].date).format('DD/MM/YYYY'),
        sortable: true,
    },
    {
        name: 'ENTRADA TERMINAL',
        selector: (row) => row?.weigths[0].hour,
        sortable: true,
    },
    {
        name: 'PESAJE ENTRADA',
        selector: (row) => row?.weigths[0].weigt,
        sortable: true,
    },
    {
        name: 'SALIDA TERMINAL',
        selector: (row) => row?.weigths[1].hour,
        sortable: true,
    },
    {
        name: 'PESAJE SALIDA',
        selector: (row) => row?.weigths[1].weigt,
        sortable: true,
    },
    {
        name: 'Num. Reporte',
        selector: (row) => row?.settled_id,
        sortable: true,
    }
];

export default function Report() {

    const requestsState = useSelector(requests);
    const [listSelect, setListSelect] = useState([]);
    const [listSelectExport, setListSelectExport] = useState([]);
    const [search, setSearch] = useState('');
    const [paginator, setPaginator] = useState({
        totalPages: 0,
        size: 15,
        page: 1
    });

    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllRequestsReport(search, paginator.page, paginator.size, history));
    }, [paginator.page, search]);

    useEffect(() => {
        if (listSelect.length > 0) {
            let data = [];

            for (let index = 0; index < listSelect.length; index++) {
                const item = listSelect[index];
                data.push({
                    ...item,
                    income_type: item.income_type.number,
                    operation_type: item.operation_type.number,
                    created_at: moment(item.created_at).format('DD/MM/YYYY'),
                    in_date: moment(item.weigths[0].date).format('DD/MM/YYYY'),
                    out_date: moment(item.weigths[1].date).format('DD/MM/YYYY'),
                    in_hour: item.weigths[0].hour,
                    in_weigt: item.weigths[0].weigt,
                    out_hour: item.weigths[1].hour,
                    out_weigt: item.weigths[1].weigt
                });
            }
            setListSelectExport(data);
        }
    }, [listSelect]);

    const goToBack = () => {
        history.goBack();
    }

    const handleChangeSelect = (selectedRows) => {
        setListSelect(selectedRows.selectedRows);
    }

    const handleExport = () => {
        if (listSelectExport.length > 0)
            document.getElementById('file-export').click();
    }

    const handleChangePage = (event, value) => {
        setPaginator({
            ...paginator,
            page: value
        });
    };

    const optionsFilter = [
        { value: 'clean', label: 'Limpiar' },
        { value: 'vehicle_plate', label: 'Placa de vehículo' },
        { value: 'conductor_name', label: 'Conductor' },
        { value: 'conductor_identification', label: 'Documento conductor' },
        { value: 'authorization_number', label: 'Num autorización' },
        { value: 'nit_transport_company', label: 'Empresa transportadora' },
        { value: 'created_at', label: 'Fecha oferta solicitud' }
    ]

    const onSearchButton = (values) => {
        const { filter, term } = values;
        if (filter && term)
            setSearch(`${filter},${term}`);
        else
            setSearch('');

        setPaginator({
            ...paginator,
            page: 1
        })
    }

    useEffect(() => {
        setPaginator({
            ...paginator,
            totalPages: Math.ceil(requestsState.meta.total / paginator.size)
        });
    }, [requestsState.requestsReport]);

    return (
        <div>
            <Header />
            <div className="container">
                <Container maxWidth="lg">

                    <div className="welcome-title">
                        <img className="icon-return" src={iconReturn} alt="" onClick={() => goToBack()} />
                        <span>Reportes al Ministerio</span>
                    </div>

                    <div className="row-table-report">
                        <Grid container spacing={1} mt={2}>
                            {/* <Grid item xs={2} className="options">
                                <img src={History} alt=""/>
                                <button className={`icon-action-report ${listSelect.length > 0 ? 'active' : ''}`} disabled={listSelect.length > 0}>
                                    Reenviar
                                </button>
                            </Grid> */}
                            <Grid item xs={2} className="options" >
                                <img src={arrowupRight} alt="" onClick={handleExport} />
                                <button className={`icon-action-report ${listSelect.length > 0 ? 'active' : ''}`} onClick={handleExport}>
                                    Exportar
                                </button>
                            </Grid>
                        </Grid>
                    </div>

                    <ExportReport listSelectExport={listSelectExport} />
                    <SearchBar onSearchButton={onSearchButton} optionsFilter={optionsFilter} />

                    <div className="box-report ">
                        <DataReportCRUD
                            data={requestsState.requestsReport}
                            columns={columnsModel}
                            handleChangeSelect={handleChangeSelect} />
                    </div>
                    <div className="paginator">
                        <Pagination
                            hidePrevButton
                            hideNextButton
                            count={paginator.totalPages}
                            page={paginator.page}
                            onChange={handleChangePage} />
                    </div>

                </Container>
            </div>
        </div>
    )
}



import ActionTypes from '../actionTypes';

const initialState = {
    show: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.START_LOADING:
            return { ...state, show: true };
        case ActionTypes.STOP_LOADING:
            return { ...state, show: false };
        default:
            return state;
    }
};

export default reducer;

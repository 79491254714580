import ActionTypes from '../actionTypes';
import axiosInstance from '../../helpers/axiosInstance';
import { startToast, stopToast } from '../Toast/toast.actions';
import { startLoading, stopLoading } from '../Loader/loader.actions';

export const getAllUsers = (
    filter,
    page,
    limit,
    sort,
    history = null,
    status = null
) => (dispatch) => {
    dispatch(startLoading());
    axiosInstance(history, dispatch)
        .get(`/users?${filter}&page=${page}&limit=${limit}${sort ? '&order=' + sort : ''}${status ? '&statusFilter=' + status : ''}`)
        .then((res) => {
            const { data } = res;
            dispatch(stopLoading());
            return dispatch({
                type: ActionTypes.LOAD_USERS_SUCCESS,
                payload: {
                    users: data.data,
                    meta: data.meta
                },
            });
        })
        .catch((err) => {
            const msg = err?.response?.message;
            const message = msg || 'Error de conexión';
            dispatch(stopLoading());
            dispatch(startToast({ message, type: 'error' }));
            return dispatch({
                type: ActionTypes.LOAD_USERS_ERROR,
                payload: {
                    message,
                },
            });
        });
};

import React, { useState, useEffect } from 'react';
import { Container } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Pagination from '@material-ui/lab/Pagination';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

// imports
import { requests, auth } from '../../redux/selectors';
import { getAllRequests } from '../../redux/Requests/requests.actions';
import Header from '../../components/Header/Header';

//images
import DataCRUD from '../../components/DataCRUD';
import SearchBar from '../../components/SearchBar';

const CustomTab = withStyles({
    root: {
        textTransform: "none",
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '20px',
        lineHeight: '24px',
        color: '#373F47',
        opacity: '0.3',

        "&.Mui-selected": {
            color: '#036BA2',
        }
    },
})(Tab);


const columnsModel = [
    {
        name: 'Cliente',
        sortField: 'user',
        selector: (row) => row?.user.client,
        sortable: true,
    },
    {
        name: 'Placa del vehículo',
        sortField: 'vehicle_plate',
        selector: (row) => row?.vehicle_plate,
        sortable: true,
    },
    {
        name: 'Conductor',
        sortField: 'conductor_name',
        selector: (row) => row?.conductor_name,
        sortable: true,
    },
    {
        name: 'Fecha',
        sortField: 'created_at',
        selector: (row) => moment(row.created_at).format("DD/MM/YYYY"),
        sortable: true,
    },
    {
        name: 'N. Autorización',
        sortField: 'authorization_number',
        selector: (row) => row.authorization_number,
        sortable: true,
    },
    {
        name: 'N. Solicitud',
        sortField: 'request_number',
        selector: (row) => row.request_number,
        sortable: true,
    },
];

const optionsFilter = [
    { value: 'clean', label: 'Limpiar' },
    { value: 'user', label: 'Cliente' },
    { value: 'vehicle_plate', label: 'Placa del vehículo' },
    { value: 'conductor_name', label: 'Conductor' },
    { value: 'created_at', label: 'Fecha' }
];

const ListRequests = (props) => {

    const requestsState = useSelector(requests);
    const authState = useSelector(auth);

    const [tabValue, setTabValue] = useState(0);
    const [paginator, setPaginator] = useState({
        totalPages: 0,
        size: 5,
        page: 1
    });

    const [order, setOrder] = useState('created_at DESC');
    const [search, setSearch] = useState('');
    const [statusSearch, setStatusSearch] = useState('created');

    const history = useHistory();
    const dispatch = useDispatch();

    const handleChangeTab = (event, value) => {
        setTabValue(value);
    }

    const handleChangePage = (event, value) => {
        setPaginator({
            ...paginator,
            page: value
        })
    };

    const goToBack = () => {
        history.push('/admin');
    }

    const handleChangeStatus = (value) => {
        setStatusSearch(value);
        setPaginator({
            ...paginator,
            page: 1
        });
        setSearch('');
    }

    useEffect(() => {
        if (authState?.user.token !== null) {
            dispatch(getAllRequests(search, paginator.page, paginator.size, order, history, statusSearch));
        }
    }, [paginator.page, search, order, statusSearch]);

    useEffect(() => {
        setPaginator({
            ...paginator,
            totalPages: Math.ceil(requestsState.meta.total / paginator.size)
        });
    }, [requestsState.requests]);

    const sortFunction = (column, sortDirection) => {
        setOrder(`${column.sortField} ${sortDirection}`);
        setPaginator({
            ...paginator,
            page: 1
        })
    }

    const onSearchButton = (values) => {
        const { filter, term } = values;
        if (filter && term)
            setSearch(`${filter},${term}`);
        else
            setSearch('');

        setPaginator({
            ...paginator,
            page: 1
        })
    }

    return (
        <>
            <Header />
            <div className="container">
                <Container maxWidth="md">
                    <div className="welcome-title">
                        <span>Estados de Vehículos</span>
                    </div>
                </Container>
                <Container maxWidth="md">
                    <SearchBar onSearchButton={onSearchButton} optionsFilter={optionsFilter} />
                    <Tabs
                        style={{ paddingTop: '50px' }}
                        variant="standard"
                        value={tabValue}
                        onChange={handleChangeTab}
                        TabIndicatorProps={{
                            style: { height: "0px", }
                        }}>
                        <CustomTab label="Por entrar" onClick={() => handleChangeStatus('created')} />
                        <CustomTab label="Ingresados" onClick={() => handleChangeStatus('inside')} />
                        <CustomTab label="Procesados" onClick={() => handleChangeStatus('out_weighing')} />
                        <CustomTab label="Reportados" onClick={() => handleChangeStatus('settled')} />
                    </Tabs>

                    <div className="row-table-body">
                        <DataCRUD
                            url="/vigilante/requests/detail"
                            data={requestsState.requests}
                            columns={columnsModel}
                            sortFunction={sortFunction} />
                        <div className="paginator">
                            <Pagination
                                hidePrevButton
                                hideNextButton
                                count={paginator.totalPages}
                                page={paginator.page}
                                onChange={handleChangePage} />
                        </div>
                    </div>
                </Container>

            </div>
        </>
    )
}

export default ListRequests;
import ActionTypes from '../actionTypes';
import axiosInstance from '../../helpers/axiosInstance';
import { startToast, stopToast } from '../Toast/toast.actions';
import { startLoading, stopLoading } from '../Loader/loader.actions';

export const getAllRequests =
    (filter,
        page,
        limit,
        sort,
        history = null,
        status = null) =>
        (dispatch, prevState) => {
            const auth = prevState().auth;
            dispatch(startLoading());
            axiosInstance(history, dispatch, auth.user.token)
                .get(`/requests?filter=${filter}&page=${page}&limit=${limit}&order=${sort}${status ? '&statusFilter=' + status : ''}`)
                .then((res) => {
                    const { data } = res;
                    dispatch(stopLoading());
                    return dispatch({
                        type: ActionTypes.LOAD_REQUESTS_SUCCESS,
                        payload: {
                            requests: data.data,
                            meta: data.meta
                        },
                    });
                })
                .catch((err) => {
                    const msg = err?.response?.message;
                    const message = msg || 'Error de conexión';
                    dispatch(stopLoading());
                    dispatch(startToast({ message, type: 'error' }));
                    return dispatch({
                        type: ActionTypes.LOAD_REQUESTS_ERROR,
                        payload: {
                            message,
                        },
                    });
                });
        };


export const addRequest =
    ({ id,
        formData,
        history = null }) =>
        (dispatch) => {
            dispatch(startLoading());
            axiosInstance(history, dispatch)
                .post('/requests', formData)
                .then((res) => {
                    const response = res.data;
                    const message = 'Solicitud agregada correctamente';
                    dispatch(stopLoading());
                    dispatch(startToast({ message, type: 'success' }));
                    history.goBack();
                    history.push(`/user/requests/detail/${response.id}?isNew=true`);
                    return dispatch({
                        type: ActionTypes.ADD_REQUEST_SUCCESS,
                        payload: { message },
                    });
                })
                .catch((err) => {
                    const msg = err?.response?.msg;
                    const message = msg || 'Error de conexión';
                    dispatch(stopLoading());
                    dispatch(startToast({ message, type: 'error' }));
                    return dispatch({
                        type: ActionTypes.ADD_REQUEST_ERROR,
                        payload: {
                            message,
                        },
                    });
                });
        };

export const getRequest =
    (id, history = null) =>
        (dispatch) => {
            dispatch(startLoading());
            axiosInstance(history, dispatch)
                .get('/requests/' + id)
                .then((res) => {
                    const { data } = res;
                    dispatch(stopLoading());
                    return dispatch({
                        type: ActionTypes.LOAD_REQUEST_SUCCESS,
                        payload: {
                            data,
                        },
                    });
                })
                .catch((err) => {
                    const msg = err?.response?.message;
                    const message = msg || 'Error de conexión';
                    dispatch(stopLoading());
                    dispatch(startToast({ message, type: 'error' }));
                    return dispatch({
                        type: ActionTypes.LOAD_REQUEST_ERROR,
                        payload: {
                            message,
                        },
                    });
                });
        };

export const updateStatusRequest =
    (id, status, history = null) =>
        (dispatch) => {
            dispatch(startLoading());
            axiosInstance(history, dispatch)
                .put('/requests/' + id, { status })
                .then((res) => {
                    const { data } = res;
                    const message = 'Solicitud modificada correctamente';
                    dispatch(stopLoading());
                    dispatch(startToast({ message, type: 'success' }));
                    dispatch(history.goBack())
                    return dispatch({
                        type: ActionTypes.UPDATE_REQUEST_SUCCESS,
                        payload: {
                            data,
                        },
                    });
                })
                .catch((err) => {
                    const msg = err?.response?.message;
                    const message = msg || 'Error de conexión';
                    dispatch(stopLoading());
                    dispatch(startToast({ message, type: 'error' }));
                    return dispatch({
                        type: ActionTypes.UPDATE_REQUEST_ERROR,
                        payload: {
                            message,
                        },
                    });
                });
        };

export const updateRequest =
    ({ id,
        formData,
        history = null }) =>
        (dispatch) => {
            dispatch(startLoading());
            axiosInstance(history, dispatch)
                .put('/requests/' + id, formData)
                .then((res) => {
                    const response = res.data;
                    const message = 'Solicitud modificada correctamente';
                    dispatch(stopLoading());
                    dispatch(startToast({ message, type: 'success' }));
                    history.goBack();
                    history.push(`/user/requests/detail/${response.id}?isNew=true`);
                    return dispatch({
                        type: ActionTypes.UPDATE_REQUEST_SUCCESS,
                        payload: { message },
                    });
                })
                .catch((err) => {
                    const msg = err?.response?.msg;
                    const message = msg || 'Error de conexión';
                    dispatch(stopLoading());
                    dispatch(startToast({ message, type: 'error' }));
                    return dispatch({
                        type: ActionTypes.UPDATE_REQUEST_ERROR,
                        payload: {
                            message,
                        },
                    });
                });
        };


export const getCant =
    (id, history = null) =>
        (dispatch, prevState) => {
            dispatch(startLoading());
            axiosInstance(history, dispatch, prevState().auth.user.token)
                .get('/requests/cant/byUser')
                .then((res) => {
                    const { data } = res;
                    dispatch(stopLoading());
                    return dispatch({
                        type: ActionTypes.CANT_REQUEST_SUCCESS,
                        payload: {
                            cant: data
                        }
                    });
                })
                .catch((err) => {
                    const msg = err?.response?.message;
                    const message = msg || 'Error de conexión';
                    dispatch(stopLoading());
                    dispatch(startToast({ message, type: 'error' }));
                    return dispatch({
                        type: ActionTypes.CANT_REQUEST_ERROR,
                        payload: {
                            message,
                        },
                    });
                });
        };


export const getAllRequestsReport = (
    filter,
    page,
    limit,
    sort,
    history = null,
    status = null) =>
    (dispatch) => {
        dispatch(startLoading());
        axiosInstance(history, dispatch)
            .get(`/requests/reports/all?filter=${filter}&page=${page}&limit=${limit}`)
            .then((res) => {
                const { result, meta } = res?.data;
                dispatch(stopLoading());
                return dispatch({
                    type: ActionTypes.LOAD_REQUESTSREPORT_SUCCESS,
                    payload: {
                        requestsReport: result,
                        meta: meta
                    },
                });
            })
            .catch((err) => {
                const msg = err?.response?.message;
                const message = msg || 'Error de conexión';
                dispatch(stopLoading());
                dispatch(startToast({ message, type: 'error' }));
                return dispatch({
                    type: ActionTypes.LOAD_REQUESTSREPORT_ERROR,
                    payload: {
                        message,
                    },
                });
            });
    };

export const deleteRequest =
    (id, history = null) =>
        (dispatch) => {
            dispatch(startLoading());
            axiosInstance(history, dispatch)
                .delete('/requests/' + id)
                .then((res) => {
                    dispatch(stopLoading());
                    dispatch(startToast({ message: "Solicitud eliminada", type: 'info' }))
                    history.goBack();
                    return dispatch({
                        type: ActionTypes.DELETE_REQUEST,
                    });
                })
                .catch((err) => {
                    const msg = err?.response?.message;
                    const message = msg || 'Error al eliminar la solicitud';
                    dispatch(stopLoading());
                    dispatch(startToast({ message, type: 'error' }));
                    return dispatch({
                        type: ActionTypes.DELETE_REQUEST_ERROR,
                    });
                });
        };

export const retryMinistryRequest =
    (id, history = null) =>
        (dispatch) => {
            dispatch(startLoading());
            axiosInstance(history, dispatch)
                .post('/requests/retryMinistry/' + id)
                .then((res) => {
                    dispatch(stopLoading());
                    dispatch(startToast({ message: "Solicitud reenviada", type: 'info' }))
                    history.goBack();
                    return dispatch({ type: ActionTypes.RETRY_MINISTRY_REQUEST });
                })
                .catch((err) => {
                    console.log('err', err)
                    const msg = err?.response?.message;
                    const message = msg || 'Error al reenviar la solicitud al ministerio.';
                    dispatch(stopLoading());
                    dispatch(startToast({ message, type: 'error' }));
                    return dispatch({ type: ActionTypes.RETRY_MINISTRY_REQUEST_ERROR });
                });
        };

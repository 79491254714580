import React, { useState, useEffect } from 'react';
import { Container } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import StatusApproved from '../../components/StatusRequest'
import { useHistory } from 'react-router-dom';
import { useLocation } from "react-router-dom";

// imports
import Header from '../../components/Header/Header.jsx';
import InfoRequest from '../../components/InfoRequest/index.js';
import { requests } from '../../redux/selectors';
import { getRequest } from '../../redux/Requests/requests.actions';

// images
import iconReturn from '../../images/return.svg';

export default function FormStatus(props) {

    const requestState = useSelector(requests);
    const [isNew, setIsNew] = useState();

    const dispatch = useDispatch();
    const history = useHistory();

    const search = useLocation().search;

    useEffect(() => {
        const isNew = new URLSearchParams(search).get("isNew");
        isNew ? setIsNew(true) : setIsNew(false);
        dispatch(getRequest(props.match.params.id, history));
    }, []);

    const goToBack = () => {
        history.goBack()
    }

    return (
        <>
            <Header
                color="request"
                logo="logoBlue"
                colorName="black" />
            <div className="container">
                <Container maxWidth="md">
                    <div className="welcome-title">
                        <img className="icon-return" src={iconReturn} alt="" onClick={() => goToBack()} />
                        {isNew ? (
                            <>
                                <span>Solicitar ingreso a puerto</span>
                            </>
                        ) : (
                            <>
                                <span>Detalle registro vehículo {requestState.data?.request?.vehicle_plate}</span>
                            </>
                        )}

                    </div>
                </Container>
                <Container maxWidth="md">
                    <div className="box-welcome-approve">
                        {requestState.data?.request && (
                            requestState.data?.request?.status_id[0]?.key === 'approved' ? (
                                <>
                                    <StatusApproved
                                        status='approved'
                                        vehicle_plate={requestState.data?.request?.vehicle_plate}
                                        request_number={requestState.data?.request?.request_number}
                                        request_date={requestState.data?.request?.created_at}
                                        id={requestState?.data.request?.id}
                                        status_internal={requestState.data?.request?.status_internal_id}
                                    />
                                </>
                            ) : (
                                <>
                                    <StatusApproved
                                        status='reject'
                                        vehicle_plate={requestState.data?.request?.vehicle_plate}
                                        request_date={requestState.data?.request?.created_at}
                                        id={requestState.data?.request?.id}
                                        response_min={requestState.data?.request?.response_min}
                                        status_internal={requestState.data?.request?.status_internal_id}
                                    />
                                </>
                            )
                        )}

                        <InfoRequest
                            operationType={requestState.data?.request?.operation_type}
                            incomeType={requestState.data?.request?.income_type}
                            nitTransportCompany={requestState.data?.request?.nit_transport_company}
                            nameTransportCompany={requestState.data?.request?.name_transport_company}
                            vehiclePlate={requestState.data?.request?.vehicle_plate}
                            multiple={requestState.data?.request?.multiple}
                            dateMultiple={requestState.data?.request?.date_multiple}
                            authorizationNumber={requestState.data?.request?.authorization_number}
                            conductorName={requestState.data?.request?.conductor_name}
                            conductorIdentification={requestState.data?.request?.conductor_identification}
                            load_observations={requestState.data?.request?.load_observations}
                            request_files={requestState.data?.request?.request_files}
                        />
                    </div>
                </Container>
            </div>
        </>
    );
}
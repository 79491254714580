import ActionTypes from '../actionTypes';
import { generateString } from '../../utils/Utils';

const initialState = {
    requests: [],
    requestsReport: [],
    meta: {},
    error: false,
    message: null,
    id_log: '',
    data: {},
    cant: ''
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.LOAD_REQUESTS_SUCCESS:
            return { ...state, error: false, requests: [...action.payload.requests], meta: action.payload.meta };
        case ActionTypes.LOAD_REQUESTSREPORT_ERROR:
            return { ...state, error: true, message: action.payload.message };
        case ActionTypes.LOAD_REQUESTSREPORT_SUCCESS:
            return { ...state, error: false, request: [], requestsReport: [...action.payload.requestsReport], meta: action.payload.meta };
        case ActionTypes.LOAD_REQUESTS_ERROR:
            return { ...state, error: true, message: action.payload.message };
        case ActionTypes.ADD_REQUEST_SUCCESS:
            return {
                ...state,
                error: false,
                id_log: generateString(),
                message: action.payload.message
            };
        case ActionTypes.ADD_REQUEST_ERROR:
            return {
                ...state,
                error: true,
                id_log: generateString(),
                message: action.payload.message,
            };
        case ActionTypes.LOAD_REQUEST_SUCCESS:
            return { ...state, error: false, data: action.payload.data };
        case ActionTypes.LOAD_REQUEST_ERROR:
            return { ...state, error: true, message: action.payload.message };
        case ActionTypes.UPDATE_REQUEST_SUCCESS:
            return { ...state, error: false, data: action.payload.data, id_log: generateString() };
        case ActionTypes.UPDATE_REQUEST_ERROR:
            return { ...state, error: true, message: action.payload.message };
        case ActionTypes.CANT_REQUEST_SUCCESS:
            return { ...state, error: false, cant: action.payload.cant };
        case ActionTypes.CANT_REQUEST_ERROR:
            return { ...state, error: true, message: action.payload.message };
        case ActionTypes.DELETE_REQUEST:
            return { ...state, error: false, id_log: generateString() };
        case ActionTypes.DELETE_REQUEST_ERROR:
            return { ...state, error: true, };
        case ActionTypes.RETRY_MINISTRY_REQUEST:
            return { ...state, error: false, id_log: generateString() };
        case ActionTypes.RETRY_MINISTRY_REQUEST_ERROR:
            return { ...state, error: true, };
        default:
            return state;
    }
};

export default reducer;

import ActionTypes from '../actionTypes';

const initialState = {
    operation_types: [],
    error: false,
    message: null,
    id_log: '',
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.LOAD_OPERATION_TYPES_SUCCESS:
            return { ...state, error: false, operation_types: [...action.payload.operation_types] };
        case ActionTypes.LOAD_OPERATION_TYPES_ERROR:
            return { ...state, error: true, message: action.payload.message };
        default:
            return state;
    }
};

export default reducer;

const types = {
    // AUTH
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_ERROR: 'LOGIN_ERROR',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    LOGOUT_ERROR: 'LOGOUT_ERROR',
    GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
    GET_PROFILE_ERROR: 'GET_PROFILE_SUCCESS',

    // loader
    START_LOADING: 'START_LOADING',
    STOP_LOADING: 'STOP_LOADING',

    // TOAST
    START_TOAST: 'START_TOAST',
    STOP_TOAST: 'STOP_TOAST',

    // coperation types
    LOAD_OPERATION_TYPES_SUCCESS: 'LOAD_OPERATION_TYPES_SUCCESS',
    LOAD_OPERATION_TYPES_ERROR: 'LOAD_OPERATION_TYPES_ERROR',

    // income types
    LOAD_INCOME_TYPES_SUCCESS: 'LOAD_INCOME_TYPES_SUCCESS',
    LOAD_INCOME_TYPES_ERROR: 'LOAD_INCOME_TYPES_ERROR',

    // requests
    LOAD_REQUESTS_SUCCESS: 'LOAD_REQUESTS_SUCCESS',
    LOAD_REQUESTS_ERROR: 'LOAD_REQUESTS_ERROR',
    LOAD_REQUESTSREPORT_SUCCESS: 'LOAD_REQUESTSREPORT_SUCCESS',
    LOAD_REQUESTSREPORT_ERROR: 'LOAD_REQUESTSREPORT_ERROR',
    ADD_REQUEST_SUCCESS: 'ADD_REQUEST_SUCCESS',
    ADD_REQUEST_ERROR: 'ADD_REQUEST_ERROR',
    LOAD_REQUEST_SUCCESS: 'LOAD_REQUEST_SUCCESS',
    LOAD_REQUEST_ERROR: 'LOAD_REQUEST_ERROR',
    UPDATE_REQUEST_SUCCESS: 'UPDATE_REQUEST_SUCCESS',
    UPDATE_REQUEST_ERROR: 'UPDATE_REQUEST_ERROR',
    CANT_REQUEST_SUCCESS: 'CANT_REQUEST_SUCCESS',
    CANT_REQUEST_ERROR: 'CANT_REQUEST_ERROR',
    DELETE_REQUEST: 'DELETE_REQUEST',
    DELETE_REQUEST_ERROR: 'DELETE_REQUEST_ERROR',
    RETRY_MINISTRY_REQUEST: 'RETRY_MINISTRY_REQUEST',
    RETRY_MINISTRY_REQUEST_ERROR: 'RETRY_MINISTRY_REQUEST_ERROR',

    // users
    LOAD_USERS_SUCCESS: 'LOAD_USERS_SUCCESS',
    LOAD_USERS_ERROR: 'LOAD_USERS_ERROR'
};

export default types;

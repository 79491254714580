import React, { useState, useEffect } from 'react'
import Header from '../../../components/Header/Header';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Grid, TextField, Container, Switch, Radio, FormControlLabel, Checkbox, RadioGroup, Hidden } from '@material-ui/core';
import Select, { components } from 'react-select';
import { Controller } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import axiosInstance from '../../../helpers/axiosInstance';
import { startLoading, stopLoading } from '../../../redux/Loader/loader.actions';
import { startToast, stopToast } from '../../../redux/Toast/toast.actions';
import ModalGeneric from '../../../components/modal/modalGeneric';

//images
import iconReturn from '../../../images/return.svg';

const UsersCreated = () => {

    const [roles, setRoles] = React.useState([]);
    const [showModal, setShowModal] = useState(false);
    const [data, setData] = React.useState(null);
    const history = useHistory();
    const dispatch = useDispatch();
    // const rolesState = useSelector([]);
    const [isEditForm, setIsEditForm] = useState(false);

    const { ValueContainer, Placeholder } = components;
    const {
        register,
        formState: { errors },
        handleSubmit,
        control,
        setValue,
        reset,
        getValues,
        setError
    } = useForm();

    useEffect(() => {
        dispatch(startLoading());
        const load = async () => {
            axiosInstance(history, dispatch)
                .get(`roles?page=1&limit=10`,)
                .then((res) => {
                    setRoles(res.data.data)
                })
                .catch((err) => console.log(err))
                .finally(dispatch(stopLoading()));
        };
        load();
    }, []);

    const onSubmit = (props) => {
        const data = {
            bodyUser: {
                name: props.user_name,
                email: props.user_email,
                document: props.user_document,
                client: props.user_client
            },
            role_id: props.user_roles.value
        }
        const load = async () => {
            dispatch(startLoading());
            axiosInstance(history, dispatch)
                .post(`/users`, data)
                .then((res) => {
                    goToBack()
                    dispatch(startToast({ message: 'Usuario creado', type: 'success' }));
                })
                .catch((err) => {
                    if (err.response.status === 400) {
                        setData(err.response.data);
                        setShowModal(true);
                        // dispatch(startToast({ message: err.response.data.message ?? '', type: 'error' }));
                    } else {
                        dispatch(startToast({ message: 'Tenemos problemas para crear el usuario', type: 'error' }));
                    }
                })
                .finally(dispatch(stopLoading()));
        };
        load();
    };


    const goToBack = () => {
        history.push('/admin/users');
    }

    const handleGoToNew = () => {
        console.log('nuevo');

    }

    const handleAceptModal = (type) => {
        setShowModal(false);
    }

    const CustomValueContainer = ({ children, ...props }) => {
        return (
            <ValueContainer {...props}>
                <Placeholder {...props} isFocused={props.isFocused}>
                    {props.selectProps.placeholder}
                </Placeholder>
                {React.Children.map(children, child =>
                    child && child.type !== Placeholder ? child : null
                )}
            </ValueContainer>
        );
    };

    return (
        <>
            <Header />
            <div className="container">
                <Container maxWidth="md">
                    <div className="welcome-title">
                        <img className="icon-return" src={iconReturn} alt="" onClick={() => history.goBack()} />
                        <span>Crear usuario</span>
                        {/* <button className="button-primary-request" variant="contained" fullWidth onClick={() => handleGoToNew()}>
                            Crear nuevo usuario
                        </button> */}
                    </div>
                </Container>
                <Container maxWidth="md" >
                    <div className="box-user-detail">
                        <Grid container spacing={2} className="select-requests">
                            <Grid Grid item xs={6}>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    id="user_name"
                                    name="user_name"
                                    label="Nombre(s) y Apellido(s) de usuario"
                                    {...register('user_name', {
                                        required: {
                                            value: true,
                                            message: 'Este campo es requerido',
                                        },
                                    })}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {errors.user_name && (
                                    <p className="text-red-700  text-xs error-text" style={{ marginTop: '0px' }}>
                                        {errors.user_name.message}
                                    </p>
                                )}
                            </Grid>
                            <Grid Grid item xs={6}>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    id="user_email"
                                    name="user_email"
                                    label="Correo electrónico del usuario"
                                    {...register('user_email', {
                                        required: {
                                            value: true,
                                            message: 'Este campo es requerido',
                                        },
                                        minLength: {
                                            value: 6,
                                            message: 'Debe tener mínimo 6 carácteres',
                                        },
                                    })}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {errors.user_email && (
                                    <p className="text-red-700  text-xs error-text" style={{ marginTop: '0px' }}>
                                        {errors.user_email.message}
                                    </p>
                                )}
                            </Grid>

                        </Grid>
                        <br />
                        <Grid container spacing={2} className="select-requests">
                            <Grid Grid item xs={6}>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    id="user_document"
                                    name="user_document"
                                    label="Cédula usuario – sin puntos"
                                    {...register('user_document', {
                                        required: {
                                            value: true,
                                            message: 'Este campo es requerido',
                                        },
                                    })}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {errors.user_document && (
                                    <p className="text-red-700  text-xs error-text" style={{ marginTop: '0px' }}>
                                        {errors.user_document.message}
                                    </p>
                                )}
                            </Grid>
                            <Grid Grid item xs={6}>
                                <Controller
                                    name="user_roles"
                                    control={control}
                                    {...register('user_roles', {
                                        required: {
                                            value: true,
                                            message: 'Este campo es requerido',
                                        },
                                    })}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            components={{
                                                ValueContainer: CustomValueContainer
                                            }}
                                            placeholder={'Tipo'}
                                            styles={{
                                                container: (provided, state) => ({
                                                    ...provided,
                                                    marginTop: 50
                                                }),
                                                valueContainer: (provided, state) => ({
                                                    ...provided,
                                                    overflow: "visible"
                                                }),
                                                placeholder: (provided, state) => ({
                                                    ...provided,
                                                    background: "#ffffff",
                                                    padding: "0 10px",
                                                    position: "absolute",
                                                    top: state.hasValue || state.selectProps.inputValue ? -15 : "10%",
                                                    transition: "top 0.1s, font-size 0.1s",
                                                    fontSize: (state.hasValue || state.selectProps.inputValue) && 13
                                                })
                                            }}
                                            options={roles?.map((v) => ({
                                                value: v.id,
                                                label: `${v.description}`,
                                            }))}
                                            isClearable
                                            isSearchable
                                            isRequired
                                        />
                                    )}
                                />
                                {errors.user_roles && (
                                    <p className="text-red-700  text-xs error-text" style={{ marginTop: '0px' }}>
                                        {errors.user_roles.message}
                                    </p>
                                )}
                            </Grid>
                        </Grid>
                        <br />
                        <Grid container spacing={2} className="select-requests">
                            <Grid Grid item xs={6}>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    id="user_client"
                                    name="user_client"
                                    label="Cliente"
                                    {...register('user_client', {
                                        required: {
                                            value: true,
                                            message: 'Este campo es requerido',
                                        },
                                    })}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {errors.user_client && (
                                    <p className="text-red-700  text-xs error-text" style={{ marginTop: '0px' }}>
                                        {errors.user_client.message}
                                    </p>
                                )}
                            </Grid>
                        </Grid>
                        <br />
                        <div>
                            <Grid item xs={1}>
                                <button className="button-acept" onClick={handleSubmit(onSubmit)}>
                                    Crear Usuario
                                </button>
                            </Grid>
                            {/* </Grid> */}
                        </div>
                    </div>
                </Container>
                <ModalGeneric
                    title={data?.title}
                    message={data?.message}
                    open={showModal}
                    onClose={() => setShowModal(false)}
                    onSave={handleAceptModal}
                />
            </div >
        </>
    )
}

export default UsersCreated;
import ModalImage from "react-modal-image-responsive";
import iconPdf from "../../images/pdf.png";
import iconDoc from "../../images/doc.png";
import iconXls from "../../images/xls.png";
import iconAdjunto from "../../images/adjunto.png";

export default function FileOption(props) {
    // let imageSelect = item;
    let items = props.image?.file?.url.split(".");
    const extencion = items[items.length - 1]?.toLowerCase();
    if (
        extencion === "png" ||
        extencion === "jpg" ||
        extencion === "jpeg"
    ) {
        return (
            <div key={props?.index}>
                <ModalImage
                    className="requests-udpload-image"
                    hideZoom="false"
                    hideDownload="true"
                    small={props?.image?.file?.url}
                    large={props?.image?.file?.url}
                    alt={props?.image?.file?.url}
                />
            </div>
        );
    } else if (extencion === "pdf") {
        return (
            <div key={props?.index}>
                <a href={props?.image?.file?.url}>
                    <img
                        src={iconPdf}
                        className="requests-udpload-image"
                        alt="Eliminar imágen"
                        onClick={() => { }}
                    />
                </a>
            </div>
        );
    } else if (extencion === "docx") {
        return (
            <div key={props?.index}>
                <a href={props?.image?.file?.url}>
                    <img
                        src={iconDoc}
                        className="requests-udpload-image"
                        alt="Eliminar imágen"
                        onClick={() => { }}
                    />
                </a>
            </div>
        );
    } else if (extencion === "xlsx") {
        return (
            <div key={props?.index}>
                <a href={props?.image?.file?.url}>
                    <img
                        src={iconXls}
                        className="requests-udpload-image"
                        alt="Eliminar imágen"
                        onClick={() => { }}
                    />
                </a>
            </div>
        );
    } else {
        return (
            <div key={props?.index}>
                <a href={props?.image?.file?.url}>
                    <img
                        src={iconAdjunto}
                        className="requests-udpload-image"
                        alt="Eliminar imágen"
                        onClick={() => { }}
                    />
                </a>
            </div>
        );
    }
}

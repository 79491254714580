import React from 'react';
import { Grid } from '@material-ui/core';
import moment from 'moment';

export default function WeigthLoad(props) {
    return (
        <>
            <span className="title-weigth">Pesado de {props.type === 'in' ? 'entrada' : 'salida'} </span>
            <div className="content-info-request">
                <Grid container spacing={1} className="row-info">
                    <Grid item xs={6}>
                        <div className="title-detail">
                            Fecha {props.type === 'in' ? 'entrada' : 'salida'} terminal
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="title-detail">
                            Hora {props.type === 'in' ? 'entrada' : 'salida'} terminal
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="info-text">
                            {moment(props.data?.date).format("DD/MM/YYYY")}
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="info-text">
                            {props.data?.hour}
                        </div>
                    </Grid>
                </Grid>

                <Grid container spacing={1} className="row-info">
                    <Grid item xs={12}>
                        <div className="title-detail">
                            Pesaje {props.type === 'in' ? 'entrada' : 'salida'}
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="weigth-value">
                            {props.data?.weigt} KG
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}
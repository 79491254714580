import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

// imports
import { logout } from '../../redux/Auth/auth.actions';
import { auth } from '../../redux/selectors';

//images
import logoheader from '../../images/logopb-white.svg';
import logoheaderBlue from '../../images/LogoPB.svg'
import iconDownArrow from '../../images/down-arrow.svg';

function Header({ color, logo, colorName }) {

    const authState = useSelector(auth);

    const [anchorEl, setAnchorEl] = useState(null);

    const dispatch = useDispatch();

    const doLogout = () => dispatch(logout());

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        setAnchorEl(null);
        dispatch(logout());
    }

    const headerclassName = color === 'request' ? 'headerRequest' : 'header';
    const contHeader = colorName === 'black' ? 'content-header-request' : 'content-header';

    return (
        <div className={headerclassName}>
            <img className="img-logo" src={logo === 'logoBlue' ? logoheaderBlue : logoheader } alt="" />
            <div className={contHeader} >
                <div className="button-close" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                    <span >{authState.user.email}</span>
                    <img src={iconDownArrow} alt="" />
                </div>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}>
                    <MenuItem onClick={handleLogout}>Cerrar sesión</MenuItem>
                </Menu>
            </div>
        </div>
    )
}

export default Header

import ActionTypes from '../actionTypes';

export const startToast =
    ({ message = null, type = null }) =>
    (dispatch) =>
        dispatch({
            type: ActionTypes.START_TOAST,
            payload: {
                message,
                type,
            },
        });
export const stopToast = () => (dispatch) => dispatch({ type: ActionTypes.STOP_TOAST });

/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { Redirect, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import jwt_decode from "jwt-decode";

// imports
import { login } from "../../redux/Auth/auth.actions";

// images
import logoPB from "../../images/LogoPB.svg";
import banner from "../../images/banner-login.png";

import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";

export default function Login({ auth }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setValue
  } = useForm();

  const onSubmit = (values) => {
    const { email, password } = values;
    dispatch(login(email, password, history));
  };
  const handleReset = () => {

  };

  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleEmailChange = (event) => {
    setValue('email', event.target.value)
  };

  const handlePasswordChange = (event) => {
    setValue('password', event.target.value)
  };

  if (auth.isAuthenticated && auth?.user?.token) {
    if (jwt_decode(auth?.user?.token).role === "admin")
      return <Redirect to="/admin" />;
    else if (jwt_decode(auth?.user?.token).role === "vigilante" || jwt_decode(auth?.user?.token).role === "syscombas")
      return <Redirect to="/vigilante" />;
    else if (jwt_decode(auth?.user?.token).role === "user")
      return <Redirect to="/user" />;
    else return <Redirect to="/login" />;
  }

  return (
    <div className="container-login">
      <div className="container-box login__container">
        <div className="container-Form-login">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <img src={logoPB} alt="" />
            </div>
            <div className="login-content">
              <h3>Iniciar sesión</h3>
              <Controller
                name="email"
                placeholder="Correo electrónico"
                control={control}
                {...register("email", {
                  required: {
                    value: true,
                    message: "Este campo es requerido",
                  },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "La dirección de correo electrónico es inválida",
                  },
                })}
                render={({ field }) => <Input
                  {...field}
                  type="text"
                  placeholder="Correo electrónico"
                  onChange={handleEmailChange}
                  className="login-input-email"
                />}
              />
              {errors.email && (
                <p className="text-red-700  text-xs error-text">
                  {errors.email.message}
                </p>
              )}
              <Controller
                name="password"
                placeholder="Contraseña"
                control={control}
                {...register("password", {
                  required: {
                    value: true,
                    message: "Este campo es requerido",
                  },
                })}
                render={({ field }) => <Input
                  {...field}
                  type={values.showPassword ? "text" : "password"}
                  placeholder="Contraseña"
                  onChange={handlePasswordChange}
                  className="login-input-pass"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          handleClickShowPassword();
                        }}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />}
              />
              {errors.password && (
                <p className="text-red-700 text-xs error-text">
                  {errors.password.message}
                </p>
              )}

              <div className="reset-password">
                <span onClick={() => history.push('/restablecer')}>¿Olvidaste tu Contraseña?</span>
              </div>
              <div className="remind-me">
                <input
                  type="checkbox"
                  className="login-input-checkbox"
                  id="cbox2"
                  value="second_checkbox"
                />
                <label for="cbox2">Recordarme</label>
              </div>
            </div>
            <button type="submit" className="button-primary-login">
              Ingresar
            </button>
          </form>
        </div>
        <div className="Banner">
          <img src={banner} alt="" />
        </div>
      </div>
      <div className="login-description">
        {" "}
        <span className="spanBold">Puerto Buenavista </span> es un terminal
        multipropósito ubicado en la zona industrial de Cartagena.{" "}
      </div>
    </div>
  );
}

// import { useState, useEffect } from "react";
import React, { useState, useEffect } from 'react'
// import { Container } from '@material-ui/core';
import { Container, Grid } from '@material-ui/core';
import Header from '../../../components/Header/Header';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import axiosInstance from '../../../helpers/axiosInstance';
import ModalChangeStatus from './ModalChangeStatus';

//images
import iconReturn from '../../../images/return.svg';
import { startLoading, stopLoading } from '../../../redux/Loader/loader.actions';
import { startToast } from '../../../redux/Toast/toast.actions';

const UsersDetail = () => {
    const [data, setData] = React.useState(null);
    const [error, setError] = React.useState(false);
    const [showModal, setShowModal] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const params = useParams();

    const goToBack = () => {
        history.goBack()
    }

    const handleGoToNew = () => {
        history.push("/admin/users/edit/" + params.id);
    }

    useEffect(() => {
        dispatch(startLoading());
        const load = async () => {
            axiosInstance(history, dispatch)
                .get(`/users/${params?.id}`)
                .then((res) => {
                    setData(res.data)
                    setError(false)

                })
                .catch((e) => {
                    setError(true)
                })
                .finally(dispatch(stopLoading()));

        };
        load();
    }, []);

    const handleAceptModal = (type) => {
        // if (requestState.data.request?.status_id[0]?.key === 'created') {
        //     dispatch(updateStatusRequest(props.match.params.id, 'inside', history));
        // } else if (requestState.data.request?.status_id[0]?.key === 'out_weighing') {
        //     dispatch(updateStatusRequest(props.match.params.id, 'settled', history));
        // }
        dispatch(startLoading());
        const load = async () => {
            axiosInstance(history, dispatch)
                .delete(`/users/${params?.id}`)
                .then((res) => {
                    dispatch(startToast({ message: 'Usuario eliminado', type: 'success' }));
                    goToBack()
                })
                .catch((e) => {
                    setError(true)
                })
                .finally(dispatch(stopLoading()));

        };
        load();
        setShowModal(false);
    }

    return (

        error
            ? <>
                <Header />
                <div className="container">
                    <Container maxWidth="md">
                        <div className="welcome-title">
                            <img className="icon-return" src={iconReturn} alt="" onClick={() => goToBack()} />
                            <span>Detalle de usuario</span>
                            {/* <button className="button-primary-request" variant="contained" fullWidth onClick={() => handleGoToNew()}>
                        Crear nuevo usuario
                    </button> */}
                        </div>
                    </Container>
                </div>
            </>
            : <>
                <Header />
                <div className="container">
                    <Container maxWidth="md">
                        <div className="welcome-title">
                            <img className="icon-return" src={iconReturn} alt="" onClick={() => goToBack()} />
                            <span>Detalle de usuario</span>
                            {/* <button className="button-primary-request" variant="contained" fullWidth onClick={() => handleGoToNew()}>
                        Crear nuevo usuario
                    </button> */}
                        </div>
                    </Container>
                    <Container maxWidth="md" >
                        <div className="box-user-detail">
                            <div className='user-detail-description'>
                                <div className="separtor">
                                    <Grid container spacing={1} className="row-info separtor" >
                                        <Grid item xs={6}>
                                            <div className="title-detail">
                                                Nombre(s) y Apellido(s) de usuario
                                            </div>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <div className="title-detail">
                                                Correo electrónico del usuario
                                            </div>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <div className="info-text">
                                                {data?.name}
                                            </div>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <div className="info-text">
                                                <p style={{ textOverflow: "ellipsis" }} >{data?.email}</p>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="separtor">
                                    <Grid container spacing={1} className="row-info">
                                        <Grid item xs={6}>
                                            <div className="title-detail">
                                                Cédula usuario
                                            </div>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <div className="title-detail">
                                                ROL
                                            </div>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <div className="info-text">
                                                {data?.document}
                                            </div>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <div className="info-text">
                                                {data?.role_users[0]?.role?.description}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="separtor">
                                    <Grid container spacing={1} className="row-info">
                                        <Grid item xs={6}>
                                            <div className="title-detail">
                                                Cliente
                                            </div>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <div className="title-detail"></div>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <div className="info-text">
                                                {data?.client}
                                            </div>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <div className="info-text"></div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                            <div>
                                <Grid container spacing={5}>
                                    <Grid item xs={6}>
                                        <button className="button-cancel" onClick={() => setShowModal(true)}>
                                            Eliminar
                                        </button>
                                        {/* <Link className="button-primary" to="/admin/users">Eliminar</Link> */}
                                    </Grid>
                                    <Grid item xs={6}>
                                        {/* <Link className="button-primary" to="/admin/users">Ingresar</Link> */}
                                        <button className="button-acept" onClick={() => handleGoToNew()}>
                                            Editar
                                        </button>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </Container>
                </div>
                <ModalChangeStatus
                    name={data?.name}
                    open={showModal}
                    onClose={() => setShowModal(false)}
                    onSave={handleAceptModal}
                />
            </>


    )
}

export default UsersDetail;
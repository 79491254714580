import { Grid } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';

//images
import iconModalClose from '../../images/icon-close-modal.svg';

export default function ModalGeneric(props) {
    return (
        <Modal
            {...props}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="modal-content"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>
            <Fade in={props.open}>
                <div className="modal-delete">
                    <div className="header-modal">
                        <div className="title-modal">
                            {
                                <>
                                    {props?.title}
                                </>
                            }
                            <img src={iconModalClose} alt="Cerrar" onClick={props.onClose} />
                        </div>
                    </div>
                    <div className="body-modal">
                        <div className="label">
                            {props?.message}
                        </div>
                    </div>
                    <div className="footer-modal">
                        <Grid container spacing={5}>
                            <Grid item xs={3}>
                                {/* <button className="button-cancel" onClick={props.onClose}>
                                    Cancelar
                                </button> */}
                            </Grid>
                            <Grid item xs={6}>
                                <button className="button-acept" onClick={props.onSave}>
                                    Aceptar
                                </button>
                            </Grid>
                            <Grid item xs={3}></Grid>
                        </Grid>
                    </div>
                </div>
            </Fade>
        </Modal>
    )
}
import React, { useEffect } from 'react';

import { Switch, Route, useLocation, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { auth as authSelector } from '../redux/selectors';
import { requests as reuqestsSelector } from '../redux/selectors';

import PrivateRoutes from './PrivateRoutes';

import Login from '../pages/Login';

import HomeAdmin from '../pages/HomeAdmin';
import Home from '../pages/Home';
import HomeVigilant from '../pages/HomeVigilant';
import Requests from '../pages/FormCreateRequests';
import ListRequests from '../pages/ListRequests';
import DetailRequest from '../pages/DetailRequest';
import RejectedRequest from '../pages/RejectedRequest';
import FormStatus from '../pages/FormStatus';
import Report from '../pages/Report';
import ListRequestsUser from '../pages/ListRequestsUser';
import Users from '../pages/Users';
import UsersDetail from '../pages/Users/detail';
import UsersEdit from '../pages/Users/edit'
import UsersCreated from '../pages/Users/created'
import ForgotPass from '../pages/Login/forgot'


export default function AppRoutes() {
    const location = useLocation();
    const auth = useSelector(authSelector);
    const requests = useSelector(reuqestsSelector);

    useEffect(() => {
        document.querySelector('html').style.scrollBehavior = 'auto';
        window.scroll({ top: 0 });
        document.querySelector('html').style.scrollBehavior = '';
    }, [location.pathname]); // triggered on route change

    return (
        <Switch>
            <Route exact path="/">
                <Redirect to="/login" />
            </Route>
            <Route exact path="/login">
                <Login auth={auth} />
            </Route>
            <Route exact path="/restablecer" component={ForgotPass} />
            <PrivateRoutes component={HomeAdmin} auth={auth} role={'admin'} exact path="/admin" />
            <PrivateRoutes component={Requests} auth={auth} role={'admin'} exact path="/admin/requests" />
            <PrivateRoutes component={ListRequests} auth={auth} role={'admin'} exact path="/admin/requests/list" />
            <PrivateRoutes component={DetailRequest} auth={auth} role={'admin'} exact path="/admin/requests/detail/:id" />
            <PrivateRoutes component={RejectedRequest} auth={auth} role={'admin'} exact path="/admin/requests/detail/rejected/:id" />
            <PrivateRoutes component={Requests} auth={auth} role={'admin'} exact path="/admin/requests/new" />
            <PrivateRoutes component={Report} auth={auth} role={'admin'} exact path="/admin/requests/report" />
            <PrivateRoutes component={Users} auth={auth} role={'admin'} exact path="/admin/users" />
            <PrivateRoutes component={UsersDetail} auth={auth} role={'admin'} exact path="/admin/users/detail/:id" />
            <PrivateRoutes component={UsersEdit} auth={auth} role={'admin'} exact path="/admin/users/edit/:id" />
            <PrivateRoutes component={UsersCreated} auth={auth} role={'admin'} exact path="/admin/users/created" />

            <PrivateRoutes component={Home} auth={auth} role={'user'} exact path="/user" />
            <PrivateRoutes component={Requests} auth={auth} role={'user'} exact path="/user/requests/new" />
            <PrivateRoutes component={FormStatus} auth={auth} role={'user'} exact path="/user/requests/detail/:id" />
            <PrivateRoutes component={ListRequestsUser} auth={auth} role={'user'} exact path="/user/requests/list" />

            <PrivateRoutes component={HomeVigilant} auth={auth} role={"vigilante"} exact path="/vigilante" />
            <PrivateRoutes component={DetailRequest} auth={auth} role={'vigilante'} exact path="/vigilante/requests/detail/:id" />

            <PrivateRoutes component={HomeVigilant} auth={auth} role={"syscombas"} exact path="/vigilante" />
            <PrivateRoutes component={DetailRequest} auth={auth} role={'syscombas'} exact path="/vigilante/requests/detail/:id" />

            <Route path="*">
                <div>
                    <h2>Página no encontrada</h2>
                </div>
            </Route>
        </Switch>
    );
}

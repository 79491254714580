import React, { useState, useEffect } from 'react';
import { Container } from '@material-ui/core';
import moment from 'moment';

import Pagination from '@material-ui/lab/Pagination';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import jwt_decode from "jwt-decode";


// imports
import { requests } from '../../redux/selectors';
import { getAllRequests } from '../../redux/Requests/requests.actions';
import { auth } from '../../redux/selectors';
import DataCRUD from '../../components/DataCRUD';
import SearchBar from '../../components/SearchBar';
import StatusDetailClient from '../../components/StatusDetailClient';

const columnsModel = [
    {
        name: 'Placa del vehículo',
        sortField: 'vehicle_plate',
        selector: (row) => row?.vehicle_plate,
        sortable: true,
    },
    {
        name: 'Conductor',
        sortField: 'conductor_name',
        selector: (row) => row?.conductor_name,
        sortable: true,
    },
    {
        name: 'Fecha',
        sortField: 'created_at',
        selector: (row) => moment(row.created_at).format("DD/MM/YYYY"),
        sortable: true,
    },
    {
        name: 'Estado',
        sortField: 'status_request',
        selector: (row) => row.status_id,
        cell: (row) =>
            <StatusDetailClient
                status_id={row?.status_id?.[0]}
                status_internal={row?.status_inter?.[0]} />,
        sortable: true,
    },
    {
        name: 'N. Autorización',
        sortField: 'authorization_number',
        selector: (row) => row.authorization_number,
        sortable: true,
    },
    {
        name: 'N. Solicitud',
        sortField: 'request_number',
        selector: (row) => row.request_number,
        sortable: true,
    },
];

const optionsFilter = [
    { value: 'clean', label: 'Limpiar' },
    { value: 'vehicle_plate', label: 'Placa de vehículo' },
    { value: 'conductor_name', label: 'Conductor' },
    { value: 'created_at', label: 'Fecha' }
]

const ListRequestsUser = (props) => {

    const requestsState = useSelector(requests);

    const [paginator, setPaginator] = useState({
        totalPages: 0,
        limit: 5,
        page: 1
    });

    const [sort, setSort] = useState('created_at DESC');
    const [search, setSearch] = useState('');

    const history = useHistory();
    const dispatch = useDispatch();

    const handleChangePage = (event, value) => {
        setPaginator({
            ...paginator,
            page: value
        });
    };

    useEffect(() => {
        dispatch(getAllRequests(search, paginator.page, paginator.limit, sort, history));
    }, [paginator.page, sort, search]);

    useEffect(() => {
        setPaginator({
            ...paginator,
            totalPages: Math.ceil(requestsState.meta.total / paginator.limit)
        });
    }, [requestsState.requests]);

    const authState = useSelector(auth);

    const handleGoToNew = () => {
        rol === 'vigilante' && (
            history.push('/vigilante/requests/new')
        )
        rol !== 'vigilante' && (
            history.push('/user/requests/new')
        )
    }

    const sortFunction = (column, sortDirection) => {
        setSort(`${column.sortField} ${sortDirection}`);
        setPaginator({
            ...paginator,
            page: 1
        })
    }

    const onSearchButton = (values) => {
        const { filter, term } = values;
        if (filter && term)
            setSearch(`${filter},${term}`);
        else
            setSearch('');

        setPaginator({
            ...paginator,
            page: 1
        })
    }

    const rol = jwt_decode(authState.user.token)?.role;
    return (
        <>
            <Container maxWidth="md">
                <div className="welcome-title">
                    <span> Bienvenid@, {authState.user.name}</span>
                    <button className="button-primary-request" variant="contained" fullWidth onClick={() => handleGoToNew()}>
                        Solicitar ingreso a puerto
                    </button>
                </div>
                <SearchBar onSearchButton={onSearchButton} optionsFilter={optionsFilter} />
                <p className="title-vehicle-entered">Vehículos ingresados</p>
                <DataCRUD
                    url={"/user/requests/detail"}
                    data={requestsState.requests}
                    columns={columnsModel}
                    sortFunction={sortFunction}
                />
                <div className="paginator">
                    <Pagination
                        hidePrevButton
                        hideNextButton
                        count={paginator.totalPages}
                        page={paginator.page}
                        onChange={handleChangePage} />
                </div>
            </Container>
        </>
    )
}

export default ListRequestsUser;


/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { useForm } from "react-hook-form";
import { Redirect, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import axiosInstance from '../../../helpers/axiosInstance';
import { startLoading, stopLoading } from '../../../redux/Loader/loader.actions';
import { startToast, stopToast } from '../../../redux/Toast/toast.actions';
import { Controller } from "react-hook-form";
import ModalGeneric from '../../../components/modal/modalGeneric';

import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";

// images
import logoPB from "../../../images/LogoPB.svg";
import banner from "../../../images/banner-login.png";

export default function ForgotPass({ auth }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const [showModal, setShowModal] = React.useState(false);
    const [data, setData] = React.useState(null);
    const [reset, setReset] = React.useState(false);
    const {
        register,
        formState: { errors },
        handleSubmit,
        control,
        setValue
    } = useForm();

    const [values, setValues] = React.useState({
        password: "",
        showPassword: false,
    });

    const handleAceptModal = (type) => {
        setShowModal(false);
    }

    const handleEmailChange = (event) => {
        setValue('email', event.target.value)
    };

    const handleCodeChange = (event) => {
        setValue('code', event.target.value)
    };

    const handlePasswordChange = (event) => {
        setValue('password', event.target.value)
    };

    const handlePasswordConfirmedChange = (event) => {
        setValue('password_confirmed', event.target.value)
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const onSubmit = (props) => {
        const body = {
            email: props.email,
        }

        const load = async () => {
            dispatch(startLoading());
            axiosInstance(history, dispatch)
                .post('/auth/getCode', body)
                .then((res) => {
                    setData(res?.data);
                    setShowModal(true);
                    setReset(true);
                })
                .catch((err) => {
                    if (err?.response?.status === 400) {
                        setData(err?.response?.data);
                        setShowModal(true);
                    } else {
                        setData({
                            title: 'Correo no encontrado',
                            message: 'Parece que el correo con el que intentas restablecer la contraseña no se encuntra registrado'
                        });
                        setShowModal(true);
                    }

                })
                .finally(dispatch(stopLoading()));
        };
        load();
    };

    const onReset = (props) => {

        const body = {
            email: props.email,
            code: props.code,
            password: props.password,
        }
        const load = async () => {
            dispatch(startLoading());
            axiosInstance(history, dispatch)
                .post('/auth/changepass', body)
                .then((res) => {
                    const msg = res?.data?.message;
                    const message = msg || 'Se resteblecio la contraseña con existo';
                    dispatch(startToast({ message, type: 'success' }));
                    history.goBack()
                })
                .catch((err) => {
                    dispatch(stopLoading());
                    if (err?.response?.status === 400) {
                        setData(err?.response?.data);
                        setShowModal(true);
                    } else if (err?.response?.status === 401) {
                        const msg = err?.response?.message;
                        const message = msg || 'Demasiados intentos';
                        dispatch(startToast({ message, type: 'error' }));
                        history.goBack()

                    } else {
                        setData({ title: 'Error ', message: 'No logramos reinicar la contraseña' });
                        setShowModal(true);
                    }

                })
                .finally(dispatch(stopLoading()));
        };
        load();
    };

    return (
        <div className="container-login">
            <div className="container-box">
                <div className="container-Form-login">
                    <form onSubmit={handleSubmit(!reset ? onSubmit : onReset)}>
                        <div>
                            <img src={logoPB} alt="" />
                        </div>
                        <div className="login-content">
                            <h3>Restablecer contraseña</h3>
                            {
                                !reset && (
                                    <>
                                        <Controller
                                            name="email"
                                            placeholder="Correo electrónico"
                                            control={control}
                                            {...register("email", {
                                                required: {
                                                    value: true,
                                                    message: "Este campo es requerido",
                                                },
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                    message: "La dirección de correo electrónico es inválida",
                                                },
                                            })}
                                            render={({ field }) => <Input
                                                {...field}
                                                type="text"
                                                placeholder="Correo electrónico"
                                                onChange={handleEmailChange}
                                                className="login-input-email"
                                            />}
                                        />
                                        {errors.email && (
                                            <p className="text-red-700  text-xs error-text">
                                                {errors.email.message}
                                            </p>
                                        )}
                                    </>
                                )
                            }
                            {
                                reset && (
                                    <>
                                        <Controller
                                            name="code"
                                            placeholder="Codigo de verificación"
                                            control={control}
                                            {...register("code", {
                                                required: {
                                                    value: true,
                                                    message: "Este campo es requerido",
                                                },
                                            })}
                                            render={({ field }) => <Input
                                                {...field}
                                                type="text"
                                                placeholder="Codigo de verificación"
                                                onChange={handleCodeChange}
                                                className="login-input-email"
                                            />}
                                        />
                                        {errors.code && (
                                            <p className="text-red-700  text-xs error-text">
                                                {errors.code.message}
                                            </p>
                                        )}
                                        <Controller
                                            name="password"
                                            placeholder="Contraseña"
                                            control={control}
                                            {...register("password", {
                                                required: {
                                                    value: true,
                                                    message: "Este campo es requerido",
                                                },
                                            })}
                                            render={({ field }) => <Input
                                                {...field}
                                                type={values.showPassword ? "text" : "password"}
                                                placeholder="Contraseña"
                                                onChange={handlePasswordChange}
                                                className="login-input-pass"
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() => {
                                                                handleClickShowPassword();
                                                            }}
                                                            onMouseDown={handleMouseDownPassword}
                                                        >
                                                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />}
                                        />
                                        {errors.password && (
                                            <p className="text-red-700 text-xs error-text">
                                                {errors.password.message}
                                            </p>
                                        )}
                                        <Controller
                                            name="password_confirmed"
                                            placeholder="Confirmar contraseña"
                                            control={control}
                                            {...register("password_confirmed", {
                                                required: {
                                                    value: true,
                                                    message: "Este campo es requerido",
                                                },
                                            })}
                                            render={({ field }) => <Input
                                                {...field}
                                                type={values.showPassword ? "text" : "password"}
                                                placeholder="Confirmar contraseña"
                                                onChange={handlePasswordConfirmedChange}
                                                className="login-input-pass"
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() => {
                                                                handleClickShowPassword();
                                                            }}
                                                            onMouseDown={handleMouseDownPassword}
                                                        >
                                                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />}
                                        />
                                        {errors.password_confirmed && (
                                            <p className="text-red-700 text-xs error-text">
                                                {errors?.password_confirmed?.message}
                                            </p>
                                        )}
                                    </>
                                )
                            }
                        </div>
                        {
                            !reset && (
                                <br></br>

                            )
                        }
                        <button type="submit" className="button-primary-login">
                            Enviar
                        </button>
                    </form>
                </div>
                <div className="Banner">
                    <img src={banner} alt="" />
                </div>
            </div>
            <div className="login-description">
                {" "}
                <span className="spanBold">Puerto Buenavista </span> es un terminal
                multipropósito ubicado en la zona industrial de Cartagena.{" "}
            </div>
            <ModalGeneric
                title={data?.title}
                message={data?.message}
                open={showModal}
                onClose={() => setShowModal(false)}
                onSave={handleAceptModal}
            />
        </div>
    );
}
import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import moment from "moment";
import ModalImage from "react-modal-image-responsive";

import FileOption from "../file/fileOption";

export default function InfoRequest({
  operationType,
  incomeType,
  nitTransportCompany,
  nameTransportCompany,
  vehiclePlate,
  multiple,
  dateMultiple,
  authorizationNumber,
  conductorName,
  conductorIdentification,
  load_observations,
  request_files,
  history,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [imgeOpen, setImageOpen] = useState();

  const openImage = (url) => {
    setImageOpen(url);
    setIsOpen(true);
  };

  return (
    <div className="content-info-request">
      <Grid container spacing={1} className="row-info">
        <Grid item xs={6}>
          <div className="title-detail">Tipo de Ingreso</div>
        </Grid>
        <Grid item xs={6}>
          <div className="title-detail">Tipo de Operación</div>
        </Grid>
        <Grid item xs={6}>
          <div className="info-text">
            <span>{`${incomeType?.number}. ${incomeType?.name}`}</span>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="info-text">
            <span>{`${operationType?.number}. ${operationType?.name}`}</span>
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={1} className="row-info">
        <Grid item xs={6}>
          <div className="title-detail">Solicitud Multiple</div>
        </Grid>
        <Grid item xs={6}>
          {multiple === 1 && <div className="title-detail">Fecha</div>}
        </Grid>
        <Grid item xs={6}>
          <div className="info-text">{multiple ? "SI" : "NO"}</div>
        </Grid>
        <Grid item xs={6}>
          {multiple === 1 && (
            <div className="info-text">
              <span>{`${moment(dateMultiple).format("DD/MM/YYYY")}`}</span>
            </div>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={1} className="row-info">
        <Grid item xs={12}>
          <div className="title-detail">NIT empresa transportadora</div>
        </Grid>
        <Grid item xs={12}>
          <div className="info-text">{nitTransportCompany}</div>
        </Grid>
        <br />
        <br />
        <Grid item xs={12}>
          <div className="title-detail">
            Nombre de la empresa transportadora
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="info-text">{nameTransportCompany}</div>
        </Grid>
      </Grid>

      <Grid container spacing={1} className="row-info">
        <Grid item xs={6}>
          <div className="title-detail">Placa vehículo</div>
        </Grid>
        <Grid item xs={6}>
          <div className="title-detail">No. Autorización</div>
        </Grid>
        <Grid item xs={6}>
          <div className="info-text">{vehiclePlate}</div>
        </Grid>
        <Grid item xs={6}>
          <div className="info-text">{authorizationNumber}</div>
        </Grid>
      </Grid>

      <Grid container spacing={1} className="row-info">
        <Grid item xs={6}>
          <div className="title-detail">Nombre conductor</div>
        </Grid>
        <Grid item xs={6}>
          <div className="title-detail">Cédula conductor</div>
        </Grid>
        <Grid item xs={6}>
          <div className="info-text">{conductorName}</div>
        </Grid>
        <Grid item xs={6}>
          <div className="info-text">{conductorIdentification}</div>
        </Grid>
      </Grid>

      <Grid container spacing={1} className="row-info">
        <Grid item xs={12}>
          <div className="title-detail">Carga / Observaciones</div>
        </Grid>
        <Grid item xs={12}>
          <div className="info-text">{load_observations}</div>
        </Grid>
      </Grid>

      <Grid container spacing={1} className="row-info">
        <Grid item xs={12}>
          <div className="title-detail">Tarjeta de propiedad del vehículo</div>
        </Grid>
        <br />
        <br />
        {request_files?.map(
          (image, index) =>
            image.file_request_type.key !== "other_image" && (
              // <div className="requests-udpload">
              <Grid Grid item>
                <FileOption
                  image={image}
                  index={index}
                />
              </Grid>
              // </div>
            )
        )}
      </Grid>

      <Grid container spacing={1} className="row-info">
        <Grid item xs={12}>
          <div className="title-detail">Otras imágenes cargadas</div>
        </Grid>
        <br />
        <br />
        {request_files?.filter(image => image.file_request_type.key === "other_image").map((image, index) => {
          return <FileOption
            image={image}
            index={index}
          />

        })}
      </Grid>
    </div>
  );
}

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import jwt_decode from "jwt-decode";

const PrivateRoutes = ({ component: Component, auth, role, ...rest }) => (
    <Route
        {...rest}
        render={(params) =>
            auth?.isAuthenticated && auth?.user?.token && jwt_decode(auth?.user?.token).role === role ? (
                <Component {...params} />
            ) : (
                <Redirect to="/login" />
            )
        }
    />
);

export default PrivateRoutes;